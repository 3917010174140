import ontodiaLogoSvg from '../images/logo.png';

const ONTODIA_WEBSITE = 'http://grasselliconsults.it'; //WEBSITE

export default (
  state = {
    watermarkSvg: ontodiaLogoSvg,
    watermarkUrl: ONTODIA_WEBSITE,
    language: 'it',
  },
  action) => {
    switch (action.type) {
      case ('UPDATECRITERIA'):
        return {
            ...state, 
            criteria: action.criteria
        };
        case('LANGUAGECHANGE'):
        return{
            ...state, 
            language:action.currentEvent
        };
        case('TAKETARGET'):
        return{
          ...state, 
          target:action.target
        };
      default:
          return state;
    }
};