import React from "react";
import request from 'superagent'

import Button from '@material-ui/core/Button';

import '../../styles/contenuti.css';

class Contenuti extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      itemList: [],
      Link: this.props.Link,
    };
  }
  
  //CREAZIONE DI LISTE E QUERY PER FILTRI
  callQuery() {
      //LISTA ORDINA DATA CRESCENTE 
      request
      .get('https://bypasscorsproxy.herokuapp.com/https://blaze.grasselliconsults.it/blazegraph/namespace/kb/sparql')
      .accept('application/json')

      .query({ query: 
        `     
        PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
        PREFIX owl:  <http://www.w3.org/2002/07/owl#>
        PREFIX bds: <http://www.bigdata.com/rdf/search#>
        PREFIX skos: <http://www.w3.org/2004/02/skos/core#>

        SELECT ?inst 
        WHERE {
        {
            SELECT DISTINCT ?inst  WHERE {
                { <` + this.state.Link + `> <http://grasselliconsults.it/contenuti> ?inst . FILTER(isIri(?inst)) }
                }
            }
        } 
        ` 
      })

      .buffer(true)

      .end((err, resp) => {
        if (!err) {
          var result = JSON.parse(resp.text).results.bindings
          //console.log(result)
          
          this.setState({
            itemList: result, 
              });
          
        }
       }
      )
  }

  componentDidMount () {
    this.callQuery();
  }

  render() {
    // console.log(this.state.itemList)
    
    return (
      <div className='finestra'>
          {this.state.itemList.map((rowdata, i) => {
              // console.log(rowdata)
              return (
                  <Button className='buttonContent' key={i}>
                        <a target="_blank" href={rowdata.inst.value} >{
                        rowdata.inst.value.replace('http://dbpedia.org/resource/','')
                        .replace('http://grasselliconsults.it/','')
                        .replace('_',' ')
                        .replace('_',' ')
                        .replace('http://blaze.grasselliconsults.it/blazegraph/namespace/kb/','')
                        }</a>
                  </Button>   
              );
          })}
      </div>
  );
}}


export default Contenuti;