import React,{Component} from 'react';
import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
import ReactPDF from '@intelllex/react-pdf';

// Component
import TitleRectangle from './TitleRectangle';
import Contenuti from './Contenuti/Contenuti';
import ReadDocumentMetadata from './Pdf_metadata';
import ExtraContent from './Contenuti/Extra';

//  Style
import '../styles/rectangle.css';

class Rectangle extends Component {

	

	constructor(props) {
		super(props);
		this.state = {
			openMetadati:false,
			openContenuti: false,
			openPdf: false,
			openVis: false,
			openExtra: false,
		};
	  }

    render() { 
		// console.log(this.props.target)
		if (this.props.target !== undefined && this.props.target.iri.includes('.pdf') ) {
			return (  
				<div className={this.state.openVis ? "RectangleOpen" : "RectangleClose"}>
					{/* BOTTONE = illumina il bottone se consigli di espandere la vista */}
					{(() => {

						if (this.state.openVis) {
							return (
								<Button onClick={() => this.setState({openVis: !this.state.openVis, openPdf:false})} variant="contained">COMPRIMI</Button>
							);
						}		

						if (!this.state.openMetadati && !this.state.openContenuti && !this.state.openPdf || this.state.openVis) {
							return (
								<Button onClick={() => this.setState({openVis: !this.state.openVis})} variant="contained">ESPANDI</Button>
							);
						}
	
						if (!this.state.openContenuti || this.state.openContenuti || this.state.openPdf) {
							return (
								<div className='pulse-button'>
									<Button onClick={() => this.setState({openVis: !this.state.openVis})} variant="contained">ESPANDI</Button>
								</div>
							);
						}
					})()}
	
					{/* <TitleRectangle text={this.props.criteria ? this.props.criteria.text: ''}/> */}
					{/* <span> Elemento selezionato: </span> { this.props.target ? this.props.target.iri : ''} */}
					<span> Documento selezionato: </span> 
						{this.props.target ? 
							JSON.stringify(this.props.target.data.label.values.map( (key)=> 
								key.text)).replace('["','').replace('"]','')
							: ''}
					
					<p>
					<br></br>
					<Button className = {this.state.openMetadati ? 'buttonAI' : 'buttonAI_no'} onClick={() => this.setState({openMetadati: !this.state.openMetadati})} variant="contained">Metadati</Button>
					<Button className = {this.state.openContenuti ? 'buttonAI' : 'buttonAI_no'} onClick={() => this.setState({openContenuti: !this.state.openContenuti})} variant="contained">Contenuti</Button>
					<Button className = {this.state.openPdf ? 'buttonAI' : 'buttonAI_no'} onClick={() => this.setState({openPdf: !this.state.openPdf, openVis: true})} variant="contained">Visualizza</Button>
					<Button className = {this.state.openExtra ? 'buttonAI' : 'buttonAI_no'} onClick={() => this.setState({openExtra: !this.state.openExtra})} variant="contained">Extra</Button>
					</p>
							
					{this.state.openAnnotatore ? <ReactPDF url={this.props.target.iri} showProgressBar showToolbox /> : ''}
					{this.state.openMetadati ? <ReadDocumentMetadata url = {this.props.target.iri}/> : ''}
					{this.state.openContenuti ? <div className='containerContenuti'><Contenuti Link = {this.props.target.iri}/></div> : ''}
					{this.state.openPdf ? <ReactPDF url={this.props.target.iri} showProgressBar showToolbox /> : ''}
					{this.state.openExtra ? <ExtraContent Link = {this.props.target.iri} /> : ''}
				</div>
			);
		}
		else return (
			<div className='infoNosel'>Cercare e selezionare un documento</div>
		)
    }
}

const mapStateToProps = state => {
	return {
		watermarkSvg: state.graph.watermarkSvg,
		watermarkUrl: state.graph.watermarkUrl,
		criteria: state.graph.criteria,
		target: state.graph.target,
		
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSearchCriteriaChanged: (value) => dispatch({
			type: 'UPDATECRITERIA',
			criteria: value
		}),
	
	
	};

};

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
})(Rectangle)






