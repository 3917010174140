import React from "react";
import request from 'superagent'

import Button from '@material-ui/core/Button';

import '../../styles/contenuti.css';
import { $CombinedState } from "redux";

class Extra extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      itemList: [],
      Link: this.props.Link,
    };
  }
  
  //CREAZIONE DI LISTE E QUERY PER FILTRI
  callQuery() {
      //LISTA ORDINA DATA CRESCENTE 
      request
      .get('https://bypasscorsproxy.herokuapp.com/https://blaze.grasselliconsults.it/blazegraph/namespace/kb/sparql')
      .accept('application/json')

      .query({ query: 
        `
        PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
        PREFIX owl:  <http://www.w3.org/2002/07/owl#>
        PREFIX dc:   <http://purl.org/dc/elements/1.1/> 

        CONSTRUCT {
          ?inst dc:abstract ?abstract;
        }
        WHERE {
          OPTIONAL {?inst dc:abstract ?abstract}
          VALUES ?labelProp { rdfs:label foaf:name }
        } VALUES (?inst) { (<` + this.state.Link + `>)}
        ` 
      })

      .buffer(true)

      .end((err, resp) => {
        if (!err) {
          var result = JSON.parse(resp.text).results.bindings
          console.log(result)
          
          this.setState({
            itemList: result, 
              });
          
        }
       }
      )
  }

  componentDidMount () {
    this.callQuery();
  }

  render() {
    // console.log(this.state.itemList)
  
  if (this.state.itemList.lenght != 0) {
    return (
      <div className='finestra'>
          {this.state.itemList.map((rowdata, i) => {
              console.log(rowdata.object.value)
              return (
                  <div className='buttonContent' key={i}>
                    {rowdata.object.value}
                  </div>   
              );
          })}
      </div>
    );
  }
  else {
    return (<div className='dd'>Non disponibile</div>)
  }
  } 
}


export default Extra;