import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import {dati} from './Dati/Data.js'
import "../styles/update_button.css";

const SparqlClient = require('sparql-http-client')

async function clickFunc() {  

  const client = new SparqlClient({
    endpointUrl: 'https://bypasscorsproxy.herokuapp.com/https://blaze.grasselliconsults.it/blazegraph/namespace/kb/sparql',
    updateUrl: 'https://bypasscorsproxy.herokuapp.com/https://blaze.grasselliconsults.it/blazegraph/namespace/kb/sparql'
  });
  const stream = await client.query.update(dati)
  alert('Aggiornamento dati')
}

async function updateFunc() {  

  const client = new SparqlClient({
    endpointUrl: 'https://bypasscorsproxy.herokuapp.com/https://blaze.grasselliconsults.it/blazegraph/namespace/kb/sparql',
    updateUrl: 'https://bypasscorsproxy.herokuapp.com/https://blaze.grasselliconsults.it/blazegraph/namespace/kb/sparql'
  });
  const stream = await client.query.update(dati)
  // alert('Aggiornamento dati')
}

function Update_button () {

  useEffect(() => {
    // Update the document title using the browser API
    updateFunc();
  });

  return (
    <div className="header">
      <div className="updateButton">
        <Button onClick={clickFunc}>Aggiorna</Button>
      </div>
    </div>
  );
}
  
export default Update_button;


