import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AppOntodiaGraph from './OntodiaGraph'
import Rectangle from './rectangle';
import Logout_button from './Logout_button';
import Update_button from './Update_button';
import Load_button from './Load_button';
import Document_button from './Document_button';

import '../styles/app.css';

function App() {

  const { target } = useSelector(state => ({
    target: state.graph.target,
  }));
  const iri = false
  // console.log(target, 'target')
  
  return (
    <div className={target ? target.iri.includes('.pdf') ?  'App' : 'App_no_target' : 'App_no_target'} >
      <Document_button/>
      <Load_button/>
      <Update_button/>
      <Logout_button/>
      <AppOntodiaGraph/>
      <Rectangle/>
      </div>
  );
}
export default App;