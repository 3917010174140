import React, { useState, useEffect } from "react";

import { PDFDocument } from 'pdf-lib';
import Button from '@material-ui/core/Button';

export default function ReadDocumentMetadata(url) {

  const [openMetadati, setOpenMetadati] = useState(false)
  const [address, setAddress] = useState(false)
  const [title, setTitle] = useState(false);
  const [author, setAuthor] = useState(false);
  const [subject, setSubject] = useState(false);
  const [creator, setCreator] = useState(false);
  const [keywords, setKeywords] = useState(false);
  const [producer, setProducer] = useState(false);
  const [creation_Date, setCreation_Date] = useState(false);
  const [modification_Date, setModification_Date] = useState(false);


  async function getMetadata() {

    try {
      const existingPdfBytes = await fetch(url.url).then(res => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(existingPdfBytes, { 
        updateMetadata: false 
      })

      const Title = pdfDoc.getTitle()
      const Author = pdfDoc.getAuthor()
      const Subject = pdfDoc.getSubject()
      const Creator = pdfDoc.getCreator()
      const Keywords = pdfDoc.getKeywords()
      const Producer = pdfDoc.getProducer()
      const Creation_Date = pdfDoc.getCreationDate()
      const Modification_Date = pdfDoc.getModificationDate()

      setAddress(url)
      setTitle(Title)
      setAuthor(Author)
      setSubject(Subject)
      setCreator(Creator)
      setKeywords(Keywords)
      setProducer(Producer)
      setCreation_Date(Creation_Date.toLocaleDateString("it-IT"))
      setModification_Date(Modification_Date.toLocaleDateString("it-IT"))

    } catch (e) {
      console.log('errore',e);
    }
  }

  useEffect(() => {
    getMetadata();
  }, [address]);

  return (
    <div className='contMeta'>
      {title ? <p> Titolo: {title}</p> : ''}
      {author ? <div className='boxM'><p className='titleM'>Autore:</p><p className='exM'>{author}</p></div> : ''}
      {subject ? <div className='boxM'><p className='titleM'>Oggetto:</p><p className='exM'>{subject}</p></div> : ''}
      {creator ? <div className='boxM'><p className='titleM'>Creatore:</p><p className='exM'>{creator}</p></div> : ''}
      {producer ? <div className='boxM'><p className='titleM'>Produttore:</p><p className='exM'>{producer}</p></div> : ''}
      {keywords ? <div className='boxM'><p className='titleM'>Parole chiave:</p><p className='exM'>{keywords}</p></div> : ''}
      {creation_Date ? <div className='boxM'><p className='titleM'>Data di creazione:</p><p className='exM'>{creation_Date}</p></div> : ''}
      {modification_Date ? <div className='boxM'><p className='titleM'>Data di modifica:</p><p className='exM'>{modification_Date}</p></div> : ''}
    </div>
  )
}