import React, { Component, useEffect } from 'react';

import { myAnalytics } from '../firebase/firebase';
import { state } from 'venti';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { loginUser } from '../actions';
import { withStyles } from '@material-ui/styles';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Logo from '../images/logo.png';
import Figure from 'react-bootstrap/Figure';

import '../styles/login.css';

const styles = () => ({
	'@global': {
		body: {},
	},
	paper: {
		marginTop: 100,
		display: 'flex',
		padding: 20,
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#f50057',
	},
	form: {
		marginTop: 1,
	},
	errorText: {
		color: '#f50057',
		marginBottom: 5,
		textAlign: 'center',
	},
});

class Login extends Component {
	state = { email: '', password: '' };

	handleEmailChange = ({ target }) => {
		this.setState({ email: target.value });
	};

	handlePasswordChange = ({ target }) => {
		this.setState({ password: target.value });
	};

	handleSubmit = () => {
		const { dispatch } = this.props;
		const { email, password } = this.state;
		dispatch(loginUser(email, password));
		myAnalytics.logEvent('login', { email });
		// VENTI login nuovo
		state.set('UserLogin', {
			email: this.state.email,
			time: new Date().toLocaleString(),
		})
	};

	render() {
		const { classes, loginError, isAuthenticated } = this.props;

		if (isAuthenticated) {
			// VENTI
			
			//myAnalytics.logEvent('login', isAuthenticated);
			// console.log("autentication", isAuthenticated)
			return <Redirect to="/" />;
		} else {
			return (
				<div className="homepage">
					<Container component="main" maxWidth="xs">
						<Paper className={classes.paper}>
							{/* <Avatar className={classes.avatar}>
								<LockOutlinedIcon />
							</Avatar> */}
							{/* <p className='titolo'>Explore</p> */}
							<Figure>
								<Figure.Image
								style={{height:'65px'}}
								src={Logo}
								/>
							</Figure>

							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								id="email"
								label="Indirizzo email"
								name="email"
								onChange={this.handleEmailChange}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								onChange={this.handlePasswordChange}
							/>
							{loginError && (
								<Typography component="p" className={classes.errorText}>
									Email o password non corretta.
								</Typography>
							)}
							<Button
								type="button"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								onClick={this.handleSubmit}
							>
								Entra
							</Button>
						</Paper>
					</Container>
					<br></br>
						<br></br>
				</div>
			);
		}
	}
}

function mapStateToProps(state) {
	return {
		isLoggingIn: state.auth.isLoggingIn,
		loginError: state.auth.loginError,
		isAuthenticated: state.auth.isAuthenticated,
	};
}

export default withStyles(styles)(connect(mapStateToProps)(Login));
