import React from "react";
import Button from "@material-ui/core/Button";
import "../styles/load_button.css";

const SparqlClient = require('sparql-http-client')

// si carica inserendo il contenuto tra `` es data.js vedi NER - Estrattore di entità

async function clickFunc() {  

  const client = new SparqlClient({
    endpointUrl: 'https://bypasscorsproxy.herokuapp.com/https://blaze.grasselliconsults.it/blazegraph/namespace/kb/sparql',
    updateUrl: 'https://bypasscorsproxy.herokuapp.com/https://blaze.grasselliconsults.it/blazegraph/namespace/kb/sparql'
  });

  let dati = prompt('Inserisci dati')
  
  const stream = await client.query.update(dati)
  alert('Caricamento dati')
}


function Load_button () {
  return (
    <div className="header">
      <div className="loadButton">
        <Button onClick={clickFunc}>Carica</Button>
      </div>
    </div>
  );
}
  
export default Load_button;


