export const dati = 
`
PREFIX schema:         <http://schema.org/> 
PREFIX documenti:      <http://grasselliconsults.it/documenti/> 
PREFIX studio:         <http://grasselliconsults.it/> 
PREFIX ex:             <http://example.org/> 
PREFIX owl:            <http://www.w3.org/2002/07/owl#> 
PREFIX link:           <http://www.w3.org/2006/link#> 
PREFIX xsd:            <http://www.w3.org/2001/XMLSchema#> 
PREFIX iter:           <http://w3id.org/sparql-generate/iter/> 
PREFIX rdfs:           <http://www.w3.org/2000/01/rdf-schema#> 
PREFIX foaf:           <http://xmlns.com/foaf/0.1/> 
PREFIX fun:            <http://w3id.org/sparql-generate/fn/> 
PREFIX crm:            <http://www.cidoc-crm.org/cidoc-crm/> 
PREFIX skos:           <http://www.w3.org/2004/02/skos/core#> 
PREFIX dc:             <http://purl.org/dc/elements/1.1/> 
PREFIX dcterms:        <http://purl.org/dc/terms/> 

INSERT DATA
{
<https://explore.grasselliconsults.it/static/media/aiuti_settore_fieristico.6045f42b.pdf> a studio:Documenti ;
	rdfs:label "aiuti_settore_fieristico" ;
	dc:title "aiuti_settore_fieristico" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "decreto sostegni" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "I focus del Sole 24 Ore Giovedì 1 Aprile 2021 – N. 8 15 In questo settore la pandemia ha imposto limitazioni dell’attività.. Il ministero ritiene che il settore sia stato penalizzato in maniera più forte della generalità dell’economia dalle misure di contenimento della pandemia. [...] Con l’articolo 1, comma 128, della legge 178/20, è stato istituito il fondo per lo sviluppo ed il sostegno delle filiere agricole, della pesca e dell’acquacoltura con lo stanziamento iniziale per l’anno in corso di 150 milioni. L’articolo 39 del decreto Sostegni incrementa di 150 milioni per il 2021 la dotazione finanziaria del fondo che è stato istituito al fine di garantire lo sviluppo e il sostegno del settore agricolo, della pesca e dell’acquacoltura.";
	studio:contenuti
		<ministro_del_Turismo>,<Ambrosio>,<Maurizio>,<Cultura>,<politiche_agricole>,<Fondo>,<Fabio_Tamburini>,<Milano>,<Milano>,<Mauro>,<Nino>,<Maria_Carla>,<Dario_Franceschini>,<diritto_d'autore>,<Francesco>,<Italia>,<Sostegno>,<Roberto>,<1_Aprile_2021>,<marzo>,<2_marzo_2021>,<07-06-2013>,<100_milioni_di_euro>,<2.400_euro>,<35mila_euro>,<75mila_euro>,<50mila_euro>.

	<ministro_del_Turismo> a <OtherEntity>,<Title> ;
		rdfs:label "ministro del Turismo".
		<Ambrosio> a <Person>,<LastName> ;
		rdfs:label "Ambrosio".
		<Maurizio> a <Location>,<GeoPoliticalEntity>,<Country> ;
		rdfs:label "Maurizio".
		<Cultura> a <Organization>,<Company>,<ConsumerGoodsCompany>,<Discretionary>,<ConsumerServicesCompany>,<MediaCompany> ;
		rdfs:label "Cultura".
		<politiche_agricole> a <OtherEntity>,<Rule>,<LawRule> ;
		rdfs:label "politiche agricole".
		<Fondo> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Fondo".
		<Fabio_Tamburini> a <Person>,<FullName> ;
		rdfs:label "Fabio Tamburini".
		<Milano> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Milano".
		<Milano> a <Location>,<GeoPoliticalEntity>,<Adm2> ;
		rdfs:label "Milano".
		<Mauro> a <Person>,<FirstName> ;
		rdfs:label "Mauro".
		<Nino> a <Person>,<FirstName> ;
		rdfs:label "Nino".
		<Maria_Carla> a <Person>,<FirstName> ;
		rdfs:label "Maria Carla".
		<Dario_Franceschini> a <Person>,<FullName> ;
		rdfs:label "Dario Franceschini".
		<diritto_d'autore> a <OtherEntity>,<Rule>,<LawRule> ;
		rdfs:label "diritto d'autore".
		<Francesco> a <Person>,<FirstName> ;
		rdfs:label "Francesco".
		<Italia> a <Location>,<GeoPoliticalEntity>,<Country> ;
		rdfs:label "Italia".
		<Sostegno> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Sostegno".
		<Roberto> a <Person> ;
		rdfs:label "Roberto".
		<1_Aprile_2021> a <time> ;
		rdfs:label "1 Aprile 2021".
		<marzo> a <time> ;
		rdfs:label "marzo".
		<2_marzo_2021> a <time> ;
		rdfs:label "2 marzo 2021".
		<07-06-2013> a <time> ;
		rdfs:label "07-06-2013".
		<100_milioni_di_euro> a "money" ;
		rdfs:label "100 milioni di euro".
		<2.400_euro> a "money" ;
		rdfs:label "2.400 euro".
		<35mila_euro> a "money" ;
		rdfs:label "35mila euro".
		<75mila_euro> a "money" ;
		rdfs:label "75mila euro".
		<50mila_euro> a "money" ;
		rdfs:label "50mila euro".
		
<https://explore.grasselliconsults.it/static/media/ammortizzatori_sociali.932c6d5b.pdf> a studio:Documenti ;
	rdfs:label "ammortizzatori_sociali" ;
	dc:title "ammortizzatori_sociali" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "aiuti covid" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "8 I focus del Sole 24 Ore Giovedì 1 Aprile 2021 – N. 8 Per la prima volta la norma non prevede un assorbimento dei precedenti periodi autorizzati che si collocano, anche parzialmente, nell’arco temporale oggetto dei nuovi trattamenti: ne deriva che le nuove settimane - richiedibili all’Inps con la nuova causale “Covid 19-Dl 41/21” - si aggiungono a quelle già introdotte dalla legge 178/20. Questo aspetto appare particolarmente interessante per i datori che non rientrano nella disciplina della Cigo; per costoro, infatti, il nuovo periodo di 28 settimane può aggiungersi a quello (12 settimane) previsto fino al 30 giugno dalla legge di Bilancio 2021. [...] Trattamento agevolato Anche per le nuove settimane di cassa integrazione che verranno erogate in base al decreto Sostegni, il Dl 41/2021, nessun contributo addizionale è dovuto dai datori di lavoro. [...] Eseguendo una media sui 5 mesi in analisi, la relazione evidenzia il numero dei lavoratori in Cigo, Cigd e Aso, nonché le retribuzioni e le ore integrate";
	studio:contenuti
		<Istituto_Nazionale_della_Previdenza_Sociale>,<Fondi>,<Fondo>,<Ilva>,<Antonino_Cannioto>,<1_Aprile_2021>,<2021>,<giugno>,<aprile>,<31_marzo_2020>,<gennaio>,<aprile>,<31_dicembre_2021>,<2020>,<23_marzo_2021>,<aprile>,<30_giugno_2021>,<aprile>,<31_dicembre_2021>,<23_marzo_2021>,<aprile_2021>,<4_gennaio_2021>,<31_marzo_2021>,<aprile_2021>,<oggi>,<oggi>,<aprile_2021>,<22_aprile_2021>,<2020>,<marzo>.

	<Istituto_Nazionale_della_Previdenza_Sociale> a <Organization>,<PublicInstitution> ;
		rdfs:label "Istituto Nazionale della Previdenza Sociale".
		<Fondi> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Fondi".
		<Fondo> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Fondo".
		<Ilva> a <Organization>,<Company>,<MaterialsCompany>,<MineralResources> ;
		rdfs:label "Ilva".
		<Antonino_Cannioto> a <Person>,<FullName> ;
		rdfs:label "Antonino Cannioto".
		<1_Aprile_2021> a <time> ;
		rdfs:label "1 Aprile 2021".
		<2021> a <time> ;
		rdfs:label "2021".
		<giugno> a <time> ;
		rdfs:label "giugno".
		<aprile> a <time> ;
		rdfs:label "aprile".
		<31_marzo_2020> a <time> ;
		rdfs:label "31 marzo 2020".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		<aprile> a <time> ;
		rdfs:label "aprile".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<2020> a <time> ;
		rdfs:label "2020".
		<23_marzo_2021> a <time> ;
		rdfs:label "23 marzo 2021".
		<aprile> a <time> ;
		rdfs:label "aprile".
		<30_giugno_2021> a <time> ;
		rdfs:label "30 giugno 2021".
		<aprile> a <time> ;
		rdfs:label "aprile".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<23_marzo_2021> a <time> ;
		rdfs:label "23 marzo 2021".
		<aprile_2021> a <time> ;
		rdfs:label "aprile 2021".
		<4_gennaio_2021> a <time> ;
		rdfs:label "4 gennaio 2021".
		<31_marzo_2021> a <time> ;
		rdfs:label "31 marzo 2021".
		<aprile_2021> a <time> ;
		rdfs:label "aprile 2021".
		<oggi> a <time> ;
		rdfs:label "oggi".
		<oggi> a <time> ;
		rdfs:label "oggi".
		<aprile_2021> a <time> ;
		rdfs:label "aprile 2021".
		<22_aprile_2021> a <time> ;
		rdfs:label "22 aprile 2021".
		<2020> a <time> ;
		rdfs:label "2020".
		<marzo> a <time> ;
		rdfs:label "marzo".
		
<https://explore.grasselliconsults.it/static/media/avvisi_bonari.1961b61f.pdf> a studio:Documenti ;
	rdfs:label "avvisi_bonari" ;
	dc:title "avvisi_bonari" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "sostegni covid" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "I focus del Sole 24 Ore Giovedì 1 Aprile 2021 – N. 8 7 L’agenzia delle Entrate liquida le imposte, i contributi e i premi dovuti, nonché i rimborsi spettanti, entro l’inizio del periodo di presentazione delle dichiarazioni relative all’anno successivo. [...] Il calo è quello che risulta dalle dichiarazioni annuali Iva presentate entro il termine stabilito per il periodo d’imposta 2020: in scadenza ordinaria, è il 30 aprile 2021. Per i contribuenti non tenuti a presentare la dichiarazione annuale Iva - quali, ad esempio, quelli con prestazioni esenti Iva o in regime forfetario - si considera l’ammontare dei ricavi o compensi risultante dalle dichiarazioni dei redditi per il periodo di imposta 2020, presentate entro i termini, cioè, di norma, entro il 30 novembre 2021. [...] Il periodo d’imposta Il riferimento è alle dichiarazioni relative al periodo d’imposta in corso al 31 dicembre 2017.";
	studio:contenuti
		<IVA>,<Guida>,<guidaallavorodigital.ilsole24ore.com>,<smart24lavoro.com>,<Giuseppe_Morina>,<Tonino_Morina>,<1_Aprile_2021>,<23_marzo_2021>,<2019>,<30_aprile_2021>,<30_novembre_2021>,<2019>,<2020>,<31_dicembre_2020>,<31_dicembre_2017>,<31_dicembre_2021>,<31_dicembre_2018>,<31_dicembre_2020>,<31_dicembre_2017>,<31_dicembre_2021>,<31_dicembre_2018>.

	<IVA> a <OtherEntity>,<IndexName>,<EconomicIndexName> ;
		rdfs:label "IVA".
		<Guida> a <Person>,<FirstName> ;
		rdfs:label "Guida".
		<guidaallavorodigital.ilsole24ore.com> a <Id>,<Url> ;
		rdfs:label "guidaallavorodigital.ilsole24ore.com".
		<smart24lavoro.com> a <Id>,<Url> ;
		rdfs:label "smart24lavoro.com".
		<Giuseppe_Morina> a <Person>,<FullName> ;
		rdfs:label "Giuseppe Morina".
		<Tonino_Morina> a <Person>,<FullName> ;
		rdfs:label "Tonino Morina".
		<1_Aprile_2021> a <time> ;
		rdfs:label "1 Aprile 2021".
		<23_marzo_2021> a <time> ;
		rdfs:label "23 marzo 2021".
		<2019> a <time> ;
		rdfs:label "2019".
		<30_aprile_2021> a <time> ;
		rdfs:label "30 aprile 2021".
		<30_novembre_2021> a <time> ;
		rdfs:label "30 novembre 2021".
		<2019> a <time> ;
		rdfs:label "2019".
		<2020> a <time> ;
		rdfs:label "2020".
		<31_dicembre_2020> a <time> ;
		rdfs:label "31 dicembre 2020".
		<31_dicembre_2017> a <time> ;
		rdfs:label "31 dicembre 2017".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<31_dicembre_2018> a <time> ;
		rdfs:label "31 dicembre 2018".
		<31_dicembre_2020> a <time> ;
		rdfs:label "31 dicembre 2020".
		<31_dicembre_2017> a <time> ;
		rdfs:label "31 dicembre 2017".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<31_dicembre_2018> a <time> ;
		rdfs:label "31 dicembre 2018".
		
<https://explore.grasselliconsults.it/static/media/decreto_sostegni_adempimenti.a1ec705e.pdf> a studio:Documenti ;
	rdfs:label "adempimenti" ;
	dc:title "decreto_sostegni_adempimenti" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "decreto sostegni" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "4 I focus del Sole 24 Ore Giovedì 20 Maggio 2021 – N. 14 La proroga è introdotta nel corpo dell’articolo 4, comma 1 del Dlgs 127/2015 che, nell’ambito delle misure in materia di trasmissione telematica delle operazioni Iva e controllo delle cessioni di beni attraverso distributori automatici, si occupa delle semplificazioni amministrative e contabili correlate, facendo venire meno l’obbligo di tenuta dei registri Iva acquisti e vendite per quei soggetti passivi di imposta che convalidano, se le informazioni proposte sono complete, o integrano nel dettaglio i dati esposti nelle bozze. [...] Adempimenti Per liquidazioni e registri Iva i precompilati partono il 1° luglio nel computo del tetto massimo, a prescindere dal metodo di rilevazione contabile. [...] Entrate hanno disposto il prolungamento del periodo di adeguamento dei registratori telematici, rispetto alla scadenza stabilita al 1° aprile 2021, tenendo conto non solo delle difficoltà dovute al perdurare della situazione emergenziale ma anche della proroga disposta proprio dal decreto Sostegni (Dl 41/2021) per la messa a disposizione delle bozze di registri Iva e liquidazioni periodiche a partire dalle operazioni Iva di luglio 2021, e della dichiarazione precompilata da quelle di gennaio 2022. [...] Il disallineamento Perciò i registri Iva e le Lipe in bozza avranno, con tutta probabilità, valori più disallineati rispetto alla situazione reale: non conterranno, almeno sino a ottobre, il dettaglio dei corrispettivi secondo il nuovo tracciato, che consente una più puntuale ricostruzione delle operazioni realizzate dagli esercenti.";
	studio:contenuti
		<IVA>,<governo>,<Alessandro_Mastromatteo>,<Benedetto_Santacroce>,<Simona_Ficola>,<20_Maggio_2021>,<ottobre>,<aprile>,<gennaio_2022>,<ottobre>,<ottobre>,<gennaio>,<gennaio>,<gennaio>,<2023>,<ottobre>.

	<IVA> a <OtherEntity>,<IndexName>,<EconomicIndexName> ;
		rdfs:label "IVA".
		<governo> a <Organization>,<Government> ;
		rdfs:label "governo".
		<Alessandro_Mastromatteo> a <Person>,<FullName> ;
		rdfs:label "Alessandro Mastromatteo".
		<Benedetto_Santacroce> a <Person>,<FullName> ;
		rdfs:label "Benedetto Santacroce".
		<Simona_Ficola> a <Person>,<FullName> ;
		rdfs:label "Simona Ficola".
		<20_Maggio_2021> a <time> ;
		rdfs:label "20 Maggio 2021".
		<ottobre> a <time> ;
		rdfs:label "ottobre".
		<aprile> a <time> ;
		rdfs:label "aprile".
		<gennaio_2022> a <time> ;
		rdfs:label "gennaio 2022".
		<ottobre> a <time> ;
		rdfs:label "ottobre".
		<ottobre> a <time> ;
		rdfs:label "ottobre".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		<2023> a <time> ;
		rdfs:label "2023".
		<ottobre> a <time> ;
		rdfs:label "ottobre".
		
<https://explore.grasselliconsults.it/static/media/decreto_sostegni_aiuti_e_mercato.04083414.pdf> a studio:Documenti ;
	rdfs:label "aiuti_e_mercato" ;
	dc:title "decreto_sostegni_aiuti_e_mercato" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "decreto sostegni" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "I focus del Sole 24 Ore Giovedì 20 Maggio 2021 – N. 14 5 Il limite di importo per gli aiuti rientranti in questa disposizione straordinaria era originariamente fissato a 800mila euro e si applica a tutti gli aiuti cumulati percepiti dall’impresa nel periodo di validità della comunicazione. [...] Se per l’anno in corso la riduzione della tassa è ancora disciplinata dal sopra cita- to articolo 1, comma 649, legge 147/13, vi è da chiedersi se i Comuni potranno negare le agevolazioni ai soggetti che recuperano e non riciclano. Questo perché la norma in effetti collega lo sconto al riciclo che è un minus rispetto al recupero. [...] Ma con il differimento al 2022 delle stesse, non è chiaro a quali condizioni spettano gli sconti per la Tari 2021.";
	studio:contenuti
		<Tari>,<Commissione_Europea>,<Bruxelles>,<Bruxelles>,<Luca_Gaiani>,<Luigi_Lovecchio>,<Unione_Europea>,<20_Maggio_2021>,<19_marzo_2020>,<settembre>,<2021>,<2022>,<Agosto>,<30_novembre_2020>,<30_aprile_2021>,<ora>,<28_gennaio_2021>,<30_settembre_2021>,<800mila_euro>,<1,8_milioni_di_euro>.

	<Tari> a <Location>,<GeoPoliticalEntity> ;
		rdfs:label "Tari".
		<Commissione_Europea> a <Organization>,<Government> ;
		rdfs:label "Commissione Europea".
		<Bruxelles> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Bruxelles".
		<Bruxelles> a <Location>,<GeoPoliticalEntity>,<Adm1> ;
		rdfs:label "Bruxelles".
		<Luca_Gaiani> a <Person>,<FullName> ;
		rdfs:label "Luca Gaiani".
		<Luigi_Lovecchio> a <Person>,<FullName> ;
		rdfs:label "Luigi Lovecchio".
		<Unione_Europea> a <Location>,<GeoPoliticalEntity> ;
		rdfs:label "Unione Europea".
		<20_Maggio_2021> a <time> ;
		rdfs:label "20 Maggio 2021".
		<19_marzo_2020> a <time> ;
		rdfs:label "19 marzo 2020".
		<settembre> a <time> ;
		rdfs:label "settembre".
		<2021> a <time> ;
		rdfs:label "2021".
		<2022> a <time> ;
		rdfs:label "2022".
		<Agosto> a <time> ;
		rdfs:label "Agosto".
		<30_novembre_2020> a <time> ;
		rdfs:label "30 novembre 2020".
		<30_aprile_2021> a <time> ;
		rdfs:label "30 aprile 2021".
		<ora> a <time> ;
		rdfs:label "ora".
		<28_gennaio_2021> a <time> ;
		rdfs:label "28 gennaio 2021".
		<30_settembre_2021> a <time> ;
		rdfs:label "30 settembre 2021".
		<800mila_euro> a "money" ;
		rdfs:label "800mila euro".
		<1,8_milioni_di_euro> a "money" ;
		rdfs:label "18 milioni di euro".
		
<https://explore.grasselliconsults.it/static/media/decreto_sostegni_crisi_impresa.d4201c82.pdf> a studio:Documenti ;
	rdfs:label "crisi_impresa" ;
	dc:title "decreto_sostegni_crisi_impresa" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "decreto sostegni" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "I focus del Sole 24 Ore Giovedì 20 Maggio 2021 – N. 14 15 Milano e altri) occorre pagare integralmente ogni credito di rango superiore prima di poter soddisfare quelli di rango inferiore: per l’articolo 160, comma 2 della Legge fallimentare (Lf), infatti, il soddisfacimento parziale dei crediti muniti di privilegio generale può trovare un fondamento giustificativo solo nell’incapienza del patrimonio mobiliare del debitore. La tesi alternativa Secondo la tesi della priorità relativa, invece, tale norma non osterebbe alla possibilità di prevedere la falcidia del credito di rango poziore e il pagamento del credito di rango minore: basta assicurare al primo un trat- [...] Conseguentemente il debitore viene dichiarato fallito, l’attività non continua, i flussi non sono pertanto prodotti e l’erario riceve un soddisfacimento peggiore di quello che potrebbe essergli alternativamente destinato con una proposta di trattamento dei crediti tributari che, derogando alla regola della priorità assoluta, escludesse il pagamento integrale dei crediti tributari e destinasse parte delle risorse a soddisfare i crediti di rango inferiore. L’articolo 182-ter Lf stabilisce inoltre il cosiddetto divieto di trattamento deteriore dei crediti tributari, che sarebbe priva di un senso logico nel caso in cui la regola della priorità assoluta dovesse considerarsi applicabile anche ai crediti tributari privilegiati.";
	studio:contenuti
		<Milano>,<Giulio_Andreani>,<Regi>,<20_Maggio_2021>,<oggi>.

	<Milano> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Milano".
		<Giulio_Andreani> a <Person>,<FullName> ;
		rdfs:label "Giulio Andreani".
		<Regi> a <Person>,<LastName> ;
		rdfs:label "Regi".
		<20_Maggio_2021> a <time> ;
		rdfs:label "20 Maggio 2021".
		<oggi> a <time> ;
		rdfs:label "oggi".
		
<https://explore.grasselliconsults.it/static/media/decreto_sostegni_debiti.ba33f6dd.pdf> a studio:Documenti ;
	rdfs:label "debiti" ;
	dc:title "decreto_sostegni_debiti" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "decreto sostegni" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "I focus del Sole 24 Ore Giovedì 20 Maggio 2021 – N. 14 7 Il divieto di notifica Dal lato dell’operatività dell’agenzia Entrate-riscossione, inoltre, la previsione dell’articolo 68 del Dl 18/2020 comportava sino al 30 aprile il divieto di notificare cartelle di pagamento e di avviare nuove azioni esecutive. [...] Per l’agente della riscossione, il debito soglia deve essere maggiore di 500mila euro per le imprese individuali e di un milione di euro, per i soggetti collettivi. [...] L’effetto è duplice: riguarda, da un lato, i versamenti e, dall’altro, le azioni di riscossione coattiva dell’agenzia delle Entrate–Riscossione (Ader). I pagamenti delle rate Sotto il primo profilo, sono dunque sospesi tutti i pagamenti delle rate delle dilazioni, concesse in base all’articolo 19, Dpr 602/1973, in scadenza tra l’8 marzo 2020 e il 30 aprile 2021.";
	studio:contenuti
		<Istituto_Nazionale_della_Previdenza_Sociale>,<Ader>,<Alert>,<Luigi_Lovecchio>,<IVA>,<20_Maggio_2021>,<maggio>,<8_marzo_2020>,<31_dicembre_2021>,<settembre>,<maggio>,<8_marzo_2020>,<aprile>,<8_marzo_2020>,<30_aprile_2021>,<8_marzo_2020>,<maggio>,<8_marzo_2020>,<31_dicembre_2021>,<2022>,<2019>,<1_100mila_euro>,<un_milione_di_euro>,<2_500mila_euro>,<10_milioni_di_euro>,<500mila_euro>,<un_milione_di_euro>,<50mila_euro>,<5mila_euro>.

	<Istituto_Nazionale_della_Previdenza_Sociale> a <Organization>,<PublicInstitution> ;
		rdfs:label "Istituto Nazionale della Previdenza Sociale".
		<Ader> a <Person>,<LastName> ;
		rdfs:label "Ader".
		<Alert> a <Person>,<LastName> ;
		rdfs:label "Alert".
		<Luigi_Lovecchio> a <Person>,<FullName> ;
		rdfs:label "Luigi Lovecchio".
		<IVA> a <OtherEntity>,<IndexName>,<EconomicIndexName> ;
		rdfs:label "IVA".
		<20_Maggio_2021> a <time> ;
		rdfs:label "20 Maggio 2021".
		<maggio> a <time> ;
		rdfs:label "maggio".
		<8_marzo_2020> a <time> ;
		rdfs:label "8 marzo 2020".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<settembre> a <time> ;
		rdfs:label "settembre".
		<maggio> a <time> ;
		rdfs:label "maggio".
		<8_marzo_2020> a <time> ;
		rdfs:label "8 marzo 2020".
		<aprile> a <time> ;
		rdfs:label "aprile".
		<8_marzo_2020> a <time> ;
		rdfs:label "8 marzo 2020".
		<30_aprile_2021> a <time> ;
		rdfs:label "30 aprile 2021".
		<8_marzo_2020> a <time> ;
		rdfs:label "8 marzo 2020".
		<maggio> a <time> ;
		rdfs:label "maggio".
		<8_marzo_2020> a <time> ;
		rdfs:label "8 marzo 2020".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<2022> a <time> ;
		rdfs:label "2022".
		<2019> a <time> ;
		rdfs:label "2019".
		<1_100mila_euro> a "money" ;
		rdfs:label "1 100mila euro".
		<un_milione_di_euro> a "money" ;
		rdfs:label "un milione di euro".
		<2_500mila_euro> a "money" ;
		rdfs:label "2 500mila euro".
		<10_milioni_di_euro> a "money" ;
		rdfs:label "10 milioni di euro".
		<500mila_euro> a "money" ;
		rdfs:label "500mila euro".
		<un_milione_di_euro> a "money" ;
		rdfs:label "un milione di euro".
		<50mila_euro> a "money" ;
		rdfs:label "50mila euro".
		<5mila_euro> a "money" ;
		rdfs:label "5mila euro".
		
<https://explore.grasselliconsults.it/static/media/decreto_sostegni_fondo_perduto.1c90cc9b.pdf> a studio:Documenti ;
	rdfs:label "fondo_perduto" ;
	dc:title "decreto_sostegni_fondo_perduto" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "decreto sostegni" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "2 I focus del Sole 24 Ore Giovedì 20 Maggio 2021 – N. 14 DECRETO SOSTEGNI 1 - LE ULTIME NOVITÀ La scadenza per l’invio È bene ricordare che l’invio della domanda per ottenere il contributo a fondo perduto dovrà avvenire entro il 28 maggio, data entro la quale si dovrà anche correggere eventualmente dichiarazioni contenenti errori Fondo perduto Istanze sospese recuperabili anche con comunicazione sostitutiva sere perfezionato entro il 28 maggio, data di scadenza di invio dell’istanza salvo proroghe. [...] Poi ci sono gli autotrasportatori con il “trimestre traslato”, i contribuenti con operazioni fuori campo Iva oggetto di fatturazione e quelli che nel 2020 non hanno registrato operazioni attive/passive. [...] In tutte le ipotesi di sospensione delle domande, non è prevista una finestra di dialogo con il contribuente o il suo professionista.";
	studio:contenuti
		<IVA>,<Giorgio_Gavelli_Lorenzo_Pegorin>,<20_Maggio_2021>,<maggio>,<2020>,<2018>,<2019>,<2018>,<2019>,<2019>,<oggi>,<2018>,<2019>,<mille_euro>,<10_milioni_di_euro>,<mille_euro>,<mille_euro>,<duemila_euro>.

	<IVA> a <OtherEntity>,<IndexName>,<EconomicIndexName> ;
		rdfs:label "IVA".
		<Giorgio_Gavelli_Lorenzo_Pegorin> a <Person>,<FullName> ;
		rdfs:label "Giorgio Gavelli Lorenzo Pegorin".
		<20_Maggio_2021> a <time> ;
		rdfs:label "20 Maggio 2021".
		<maggio> a <time> ;
		rdfs:label "maggio".
		<2020> a <time> ;
		rdfs:label "2020".
		<2018> a <time> ;
		rdfs:label "2018".
		<2019> a <time> ;
		rdfs:label "2019".
		<2018> a <time> ;
		rdfs:label "2018".
		<2019> a <time> ;
		rdfs:label "2019".
		<2019> a <time> ;
		rdfs:label "2019".
		<oggi> a <time> ;
		rdfs:label "oggi".
		<2018> a <time> ;
		rdfs:label "2018".
		<2019> a <time> ;
		rdfs:label "2019".
		<mille_euro> a "money" ;
		rdfs:label "mille euro".
		<10_milioni_di_euro> a "money" ;
		rdfs:label "10 milioni di euro".
		<mille_euro> a "money" ;
		rdfs:label "mille euro".
		<mille_euro> a "money" ;
		rdfs:label "mille euro".
		<duemila_euro> a "money" ;
		rdfs:label "duemila euro".
		
<https://explore.grasselliconsults.it/static/media/decreto_sostegni_immobili.955e58b6.pdf> a studio:Documenti ;
	rdfs:label "immobili" ;
	dc:title "decreto_sostegni_immobili" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "decreto sostegni" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "6 I focus del Sole 24 Ore Giovedì 20 Maggio 2021 – N. 14 Immobili Esenzione dalla prima rata Imu con fatturato in calo almeno del 30% contributo a fondo perduto, prescindendo dal comparto produttivo di appartenenza. Come per le fattispecie di cui alle lettere b) e d) del comma 599 della legge di Bilancio 2021, l’esenzione è limitata «agli immobili nei quali i soggetti passivi esercitano le attività di cui siano anche gestori». [...] Il beneficio spetta a prescindere dal settore di attività e dal regime fiscale con cui si determina il reddito [...] 216 Milioni Il costo dell’operazione Imu è stimato in 216 milioni, di cui 142,5 di spettanza dei Comuni e 73,5 dello Stato lo 26 del Tuir, che prevede la concorrenza dei redditi fondiari al reddito complessivo indipendentemente dall’effettiva percezione.";
	studio:contenuti
		<IMU>,<Imposta_sul_Reddito_delle_Persone_Fisiche>,<IVA>,<Istituto_Nazionale_della_Previdenza_Sociale>,<Francesco_Giuseppe_Carucci>,<Alessandra_Caputo>,<20_Maggio_2021>,<23_marzo_2021>,<2020>,<2019>,<2019>,<gennaio>,<giugno>,<19_marzo_2020>,<giugno>,<Ora>,<gennaio>,<ora>,<2020>,<2020>,<2020>,<2020>,<2020>.

	<IMU> a <OtherEntity>,<Rule>,<LawRule> ;
		rdfs:label "IMU".
		<Imposta_sul_Reddito_delle_Persone_Fisiche> a <OtherEntity>,<IndexName>,<EconomicIndexName> ;
		rdfs:label "Imposta sul Reddito delle Persone_Fisiche".
		<IVA> a <OtherEntity>,<IndexName>,<EconomicIndexName> ;
		rdfs:label "IVA".
		<Istituto_Nazionale_della_Previdenza_Sociale> a <Organization>,<PublicInstitution> ;
		rdfs:label "Istituto Nazionale della Previdenza Sociale".
		<Francesco_Giuseppe_Carucci> a <Person>,<FullName> ;
		rdfs:label "Francesco Giuseppe Carucci".
		<Alessandra_Caputo> a <Person>,<FullName> ;
		rdfs:label "Alessandra Caputo".
		<20_Maggio_2021> a <time> ;
		rdfs:label "20 Maggio 2021".
		<23_marzo_2021> a <time> ;
		rdfs:label "23 marzo 2021".
		<2020> a <time> ;
		rdfs:label "2020".
		<2019> a <time> ;
		rdfs:label "2019".
		<2019> a <time> ;
		rdfs:label "2019".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		<giugno> a <time> ;
		rdfs:label "giugno".
		<19_marzo_2020> a <time> ;
		rdfs:label "19 marzo 2020".
		<giugno> a <time> ;
		rdfs:label "giugno".
		<Ora> a <time> ;
		rdfs:label "Ora".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		<ora> a <time> ;
		rdfs:label "ora".
		<2020> a <time> ;
		rdfs:label "2020".
		<2020> a <time> ;
		rdfs:label "2020".
		<2020> a <time> ;
		rdfs:label "2020".
		<2020> a <time> ;
		rdfs:label "2020".
		<2020> a <time> ;
		rdfs:label "2020".
		
<https://explore.grasselliconsults.it/static/media/decreto_sostegni_imprese_e_consumatori.a82f7c19.pdf> a studio:Documenti ;
	rdfs:label "imprese_e_consumatori" ;
	dc:title "decreto_sostegni_imprese_e_consumatori" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "decreto sostegni" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "I focus del Sole 24 Ore Giovedì 20 Maggio 2021 – N. 14 9 Ha quindi un periodo di tregua chi ha l’autorizzazione al commercio in sede ambulante, cioè chi non ha un esercizio in negozio fisso, ma utilizzano aree pubbliche, con provvedimenti comunali. Il problema si ripropone ciclicamente, da quando la direttiva ha introdotto un generale regime di competizione per ottenere titoli su aree pubbliche: si tratti di demanio marittimo per le concessioni balneari o di demanio stradale (vie e piazze) per gli ambulanti, l’orientamento dell’Unione europea è il medesimo: le procedure devono essere aperte a tutti e con selezione tra candidati ispirate a [...] Ristrutturazione del mutuo per chi è in difficoltà Case e affitti Il beneficio è riservato all’abitazione principale [...] Prorogate al 30 ottobre 2021 le concessioni per il commercio su aree pubbliche, con una specifica norma tra quelle per l’emergenza Covid.";
	studio:contenuti
		<Bolkestein>,<Unione_Europea>,<Consiglio_di_Stato>,<Fondo>,<Arriva>,<Toscana>,<corte_costituzionale>,<PA>,<Saverio_Fossati>,<Guglielmo_Saporito>,<20_Maggio_2021>,<31_dicembre_2022>,<21_marzo_2021>,<28_febbraio_2020>,<30_settembre_2020>,<30_settembre_2021>,<31_dicembre_2021>,<ottobre>,<30_giugno_2021>,<28_febbraio_2020>,<30_giugno_2021>,<28_febbraio_2020>,<marzo_2021>,<Ora>,<ottobre>,<30_ottobre_2021>,<21_aprile_2021>,<ora>,<ottobre>,<250mila_euro>.

	<Bolkestein> a <Person>,<LastName> ;
		rdfs:label "Bolkestein".
		<Unione_Europea> a <Location>,<GeoPoliticalEntity> ;
		rdfs:label "Unione Europea".
		<Consiglio_di_Stato> a <Organization>,<Government> ;
		rdfs:label "Consiglio di Stato".
		<Fondo> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Fondo".
		<Arriva> a <Organization>,<Company>,<IndustrialCompany>,<TransportationCompany> ;
		rdfs:label "Arriva".
		<Toscana> a <Location>,<GeoPoliticalEntity>,<Adm1> ;
		rdfs:label "Toscana".
		<corte_costituzionale> a <Organization>,<PublicInstitution> ;
		rdfs:label "corte costituzionale".
		<PA> a <Organization>,<Company>,<ConsumerGoodsCompany>,<Discretionary>,<ConsumerServicesCompany>,<MediaCompany> ;
		rdfs:label "PA".
		<Saverio_Fossati> a <Person>,<FullName> ;
		rdfs:label "Saverio Fossati".
		<Guglielmo_Saporito> a <Person>,<FullName> ;
		rdfs:label "Guglielmo Saporito".
		<20_Maggio_2021> a <time> ;
		rdfs:label "20 Maggio 2021".
		<31_dicembre_2022> a <time> ;
		rdfs:label "31 dicembre 2022".
		<21_marzo_2021> a <time> ;
		rdfs:label "21 marzo 2021".
		<28_febbraio_2020> a <time> ;
		rdfs:label "28 febbraio 2020".
		<30_settembre_2020> a <time> ;
		rdfs:label "30 settembre 2020".
		<30_settembre_2021> a <time> ;
		rdfs:label "30 settembre 2021".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<ottobre> a <time> ;
		rdfs:label "ottobre".
		<30_giugno_2021> a <time> ;
		rdfs:label "30 giugno 2021".
		<28_febbraio_2020> a <time> ;
		rdfs:label "28 febbraio 2020".
		<30_giugno_2021> a <time> ;
		rdfs:label "30 giugno 2021".
		<28_febbraio_2020> a <time> ;
		rdfs:label "28 febbraio 2020".
		<marzo_2021> a <time> ;
		rdfs:label "marzo 2021".
		<Ora> a <time> ;
		rdfs:label "Ora".
		<ottobre> a <time> ;
		rdfs:label "ottobre".
		<30_ottobre_2021> a <time> ;
		rdfs:label "30 ottobre 2021".
		<21_aprile_2021> a <time> ;
		rdfs:label "21 aprile 2021".
		<ora> a <time> ;
		rdfs:label "ora".
		<ottobre> a <time> ;
		rdfs:label "ottobre".
		<250mila_euro> a "money" ;
		rdfs:label "250mila euro".
		
<https://explore.grasselliconsults.it/static/media/decreto_sostegni_integrazione_al_reddito.8fa2a59f.pdf> a studio:Documenti ;
	rdfs:label "integrazione_al_reddito" ;
	dc:title "decreto_sostegni_integrazione_al_reddito" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "decreto sostegni" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "10 I focus del Sole 24 Ore Giovedì 20 Maggio 2021 – N. 14 Nuovo comma nel decreto Si è posto il problema della scopertura di cassa integrazione nelle giornate dal 26 al 31 marzo soprattutto per le aziende che, essendo in maggiore difficoltà o addirittura chiuse, hanno dovuto utilizzare in continuità le 12 settimane previste dalla legge di Bilancio 2021. [...] In questa disposizione non rientra l’ipotesi di pre- sentazione della domanda per la copertura retroattiva del buco di cassa integrazione tra il 26 marzo e il 31 marzo. [...] Il primo, nei confronti dei lavoratori, si può risolvere nella prima busta paga utile (probabilmente maggio), in cui il datore di lavoro può stornare le giornate di ferie e permessi riconoscendo contestualmente le giornate di cassa integrazione (salvo il caso del pagamento diretto). [...] Click day per le domande di cassa integrazione scadute tra il 1° gennaio e il 31 marzo.";
	studio:contenuti
		<Istituto_Nazionale_della_Previdenza_Sociale>,<Enzo>,<Fusco>,<20_Maggio_2021>,<aprile>,<31_dicembre_2021>,<30_dicembre_2020>,<gennaio>,<31_marzo_2021>,<marzo>,<marzo>,<gennaio>,<marzo>,<giugno>,<aprile>,<aprile>,<lunedì>,<domenica>,<marzo>,<maggio>,<maggio>,<marzo>,<marzo>,<aprile>,<gennaio>.

	<Istituto_Nazionale_della_Previdenza_Sociale> a <Organization>,<PublicInstitution> ;
		rdfs:label "Istituto Nazionale della Previdenza Sociale".
		<Enzo> a <Person>,<FirstName> ;
		rdfs:label "Enzo".
		<Fusco> a <Person>,<LastName> ;
		rdfs:label "Fusco".
		<20_Maggio_2021> a <time> ;
		rdfs:label "20 Maggio 2021".
		<aprile> a <time> ;
		rdfs:label "aprile".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<30_dicembre_2020> a <time> ;
		rdfs:label "30 dicembre 2020".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		<31_marzo_2021> a <time> ;
		rdfs:label "31 marzo 2021".
		<marzo> a <time> ;
		rdfs:label "marzo".
		<marzo> a <time> ;
		rdfs:label "marzo".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		<marzo> a <time> ;
		rdfs:label "marzo".
		<giugno> a <time> ;
		rdfs:label "giugno".
		<aprile> a <time> ;
		rdfs:label "aprile".
		<aprile> a <time> ;
		rdfs:label "aprile".
		<lunedì> a <time> ;
		rdfs:label "lunedì".
		<domenica> a <time> ;
		rdfs:label "domenica".
		<marzo> a <time> ;
		rdfs:label "marzo".
		<maggio> a <time> ;
		rdfs:label "maggio".
		<maggio> a <time> ;
		rdfs:label "maggio".
		<marzo> a <time> ;
		rdfs:label "marzo".
		<marzo> a <time> ;
		rdfs:label "marzo".
		<aprile> a <time> ;
		rdfs:label "aprile".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		
<https://explore.grasselliconsults.it/static/media/decreto_sostegni_lavoratori_autonomi.9d101833.pdf> a studio:Documenti ;
	rdfs:label "lavoratori_autonomi" ;
	dc:title "decreto_sostegni_lavoratori_autonomi" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "decreto sostegni" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "L'esonero è riconosciuto nel limite di 3mila euro su base annua e deve essere riparametrato e applicato su base mensile per ciascun lavoratore autonomo o professionista attivo al 1° gennaio 2021. [...] Lavoratori autonomi Più fondi per l’esonero contributivo Fino a 3mila euro sugli importi 2021 contribuzione previdenziale dovuta per l’anno 2021, da versare con rate o acconti con scadenza ordinaria entro il 31 dicembre 2021. [...] Nel caso di superamento del limite di spesa, l’istituto di previdenza provvederà a ridurre l’agevolazione in misura proporzionale tra i beneficiari. [...] Agevolati gli iscritti Inps e quelli delle Casse di previdenza il cui fatturato nel 2020 si è ridotto di almeno un terzo rispetto al 2019 [...] Durante l’iter di conversione in legge del Dl, è stato firmato dal ministro del Lavoro, il decreto interministeriale di attuazione di questa agevolazione.";
	studio:contenuti
		<Istituto_Nazionale_della_Previdenza_Sociale>,<IVA>,<Commissione_Europea>,<Fabio_Venanzi>,<Fondo>,<ministro_del_Lavoro>,<20_Maggio_2021>,<2020>,<gennaio>,<31_dicembre_2021>,<31_ottobre_2021>,<31_dicembre_2021>,<2020>,<31_ottobre_2021>,<2020>,<50mila_euro>,<3mila_euro>,<3mila_euro>,<50mila_euro>,<50mila_euro>.

	<Istituto_Nazionale_della_Previdenza_Sociale> a <Organization>,<PublicInstitution> ;
		rdfs:label "Istituto Nazionale della Previdenza Sociale".
		<IVA> a <OtherEntity>,<IndexName>,<EconomicIndexName> ;
		rdfs:label "IVA".
		<Commissione_Europea> a <Organization>,<Government> ;
		rdfs:label "Commissione Europea".
		<Fabio_Venanzi> a <Person>,<FullName> ;
		rdfs:label "Fabio Venanzi".
		<Fondo> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Fondo".
		<ministro_del_Lavoro> a <OtherEntity>,<Title> ;
		rdfs:label "ministro del Lavoro".
		<20_Maggio_2021> a <time> ;
		rdfs:label "20 Maggio 2021".
		<2020> a <time> ;
		rdfs:label "2020".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<31_ottobre_2021> a <time> ;
		rdfs:label "31 ottobre 2021".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<2020> a <time> ;
		rdfs:label "2020".
		<31_ottobre_2021> a <time> ;
		rdfs:label "31 ottobre 2021".
		<2020> a <time> ;
		rdfs:label "2020".
		<50mila_euro> a "money" ;
		rdfs:label "50mila euro".
		<3mila_euro> a "money" ;
		rdfs:label "3mila euro".
		<3mila_euro> a "money" ;
		rdfs:label "3mila euro".
		<50mila_euro> a "money" ;
		rdfs:label "50mila euro".
		<50mila_euro> a "money" ;
		rdfs:label "50mila euro".
		
<https://explore.grasselliconsults.it/static/media/decreto_sostegni_liquidità.356eeb42.pdf> a studio:Documenti ;
	rdfs:label "liquidità" ;
	dc:title "decreto_sostegni_liquidità" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "decreto sostegni" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "8 I focus del Sole 24 Ore Giovedì 20 Maggio 2021 – N. 14 Al fine del rispetto dell’importo massimo, si considerano la sorte capitale, le sanzioni e gli interessi per ritardata iscrizione a ruolo. Ciò che rileva è il singolo carico e non il totale recato in cartella. Ogni carico deriva da un provvedimento che va considerato autonomamente ai fini della verifica del limite di 5mila euro. [...] Il beneficio compete solo ai soggetti che hanno dichiarato per il 2019 un reddito imponibile non superiore a 30mila euro.";
	studio:contenuti
		<IVA>,<Imposta_sul_Reddito_delle_Persone_Fisiche>,<IMU>,<Bo>,<Bo>,<Ministero_dell'Interno>,<Luigi_Lovecchio>,<20_Maggio_2021>,<23_marzo_2021>,<2010>,<31_dicembre_2020>,<23_marzo_2021>,<2021>,<31_ottobre_2020>,<novembre>,<31_ottobre_2020>,<ottobre>,<24_settembre_2014>,<31_dicembre_2010>,<5mila_euro>,<5mila_euro>,<3mila_euro>,<4mila_euro>,<2.500_euro>,<30mila_euro>,<5mila_euro>,<5mila_euro>,<5mila_euro>,<5mila_euro>,<30mila_euro>.

	<IVA> a <OtherEntity>,<IndexName>,<EconomicIndexName> ;
		rdfs:label "IVA".
		<Imposta_sul_Reddito_delle_Persone_Fisiche> a <OtherEntity>,<IndexName>,<EconomicIndexName> ;
		rdfs:label "Imposta sul Reddito delle Persone_Fisiche".
		<IMU> a <OtherEntity>,<Rule>,<LawRule> ;
		rdfs:label "IMU".
		<Bo> a <Person>,<LastName> ;
		rdfs:label "Bo".
		<Bo> a <Person>,<LastName> ;
		rdfs:label "Bo".
		<Ministero_dell'Interno> a <Organization>,<Government> ;
		rdfs:label "Ministero dell'Interno".
		<Luigi_Lovecchio> a <Person>,<FullName> ;
		rdfs:label "Luigi Lovecchio".
		<20_Maggio_2021> a <time> ;
		rdfs:label "20 Maggio 2021".
		<23_marzo_2021> a <time> ;
		rdfs:label "23 marzo 2021".
		<2010> a <time> ;
		rdfs:label "2010".
		<31_dicembre_2020> a <time> ;
		rdfs:label "31 dicembre 2020".
		<23_marzo_2021> a <time> ;
		rdfs:label "23 marzo 2021".
		<2021> a <time> ;
		rdfs:label "2021".
		<31_ottobre_2020> a <time> ;
		rdfs:label "31 ottobre 2020".
		<novembre> a <time> ;
		rdfs:label "novembre".
		<31_ottobre_2020> a <time> ;
		rdfs:label "31 ottobre 2020".
		<ottobre> a <time> ;
		rdfs:label "ottobre".
		<24_settembre_2014> a <time> ;
		rdfs:label "24 settembre 2014".
		<31_dicembre_2010> a <time> ;
		rdfs:label "31 dicembre 2010".
		<5mila_euro> a "money" ;
		rdfs:label "5mila euro".
		<5mila_euro> a "money" ;
		rdfs:label "5mila euro".
		<3mila_euro> a "money" ;
		rdfs:label "3mila euro".
		<4mila_euro> a "money" ;
		rdfs:label "4mila euro".
		<2.500_euro> a "money" ;
		rdfs:label "2.500 euro".
		<30mila_euro> a "money" ;
		rdfs:label "30mila euro".
		<5mila_euro> a "money" ;
		rdfs:label "5mila euro".
		<5mila_euro> a "money" ;
		rdfs:label "5mila euro".
		<5mila_euro> a "money" ;
		rdfs:label "5mila euro".
		<5mila_euro> a "money" ;
		rdfs:label "5mila euro".
		<30mila_euro> a "money" ;
		rdfs:label "30mila euro".
		
<https://explore.grasselliconsults.it/static/media/decreto_sostegni_rivalutazioni.106fb5ff.pdf> a studio:Documenti ;
	rdfs:label "rivalutazioni" ;
	dc:title "decreto_sostegni_rivalutazioni" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "decreto sostegni" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "Rivalutazioni Estensione dei termini al 2021 per i beni non rivalutati nel 2020 comma 4-bis “apre” al bilancio successivo a quello in corso il 31 dicembre 2020 (bilancio 2021, se solare). Con due novità: O accesso al regime per i soli beni non rivalutati nel bilancio precedente (del 2020, se solare); O esclusione della possibilità di affrancare il saldo attivo previsto dall’articolo 110, comma 3 e di farsi riconoscere gli effetti ai fini fiscali in base al comma 4. [...] Ma nel 2021 si può ancora fruire della rivalutazione prevista dalla legge 160/2019 pagando un’imposta sostituiva del 12% per i beni ammortizzabili e del 10% per i non ammortizzabili, con effetti fiscali molto differiti nel tempo. [...] Imposta sostitutiva 12% per i beni ammortizzabili e 10% per i beni non ammortizzabili 3% sia per i beni ammortizzabili che per i beni non ammortizzabili Non è prevista La rivalutazione è gratuita 12% per i beni ammortizzabili e 10% per i beni non ammortizzabili (l'imposta non è dovuta fino a concorrenza del 70% delle perdite fiscali pregresse)";
	studio:contenuti
		<Beni>,<Beni>,<Max>,<Fabio_Rousset>,<Simone>,<Suma>,<Ter>,<Ter>,<Massimo>,<20_Maggio_2021>,<2020>,<31_dicembre_2020>,<2020>,<2021>,<31_dicembre_2019>,<2020>,<AGOSTO_2020>,<31.12.2020>,<31.12.2018>,<31.12.2019>,<31.12.2019>,<31.12.2019>,<31.12.2018>,<31.12.2018>,<31.12.2019>,<31.12.2020>,<31.12.2021>,<31.12.2020>,<31.12.2021>,<31.12.2019>,<31.12.2018>,<31.12.2019>,<31.12.2020>,<31.12.2021>,<31.12.2021>.

	<Beni> a <Person>,<FirstName> ;
		rdfs:label "Beni".
		<Beni> a <Location>,<GeoPoliticalEntity>,<Adm1> ;
		rdfs:label "Beni".
		<Max> a <Person>,<LastName> ;
		rdfs:label "Max".
		<Fabio_Rousset> a <Person>,<FullName> ;
		rdfs:label "Fabio Rousset".
		<Simone> a <Person>,<FirstName> ;
		rdfs:label "Simone".
		<Suma> a <Organization>,<Company>,<ConsumerGoodsCompany>,<Staples> ;
		rdfs:label "Suma".
		<Ter> a <Person>,<LastName> ;
		rdfs:label "Ter".
		<Ter> a <Location>,<GeographicalEntity>,<WaterForm>,<River> ;
		rdfs:label "Ter".
		<Massimo> a <Person>,<FirstName> ;
		rdfs:label "Massimo".
		<20_Maggio_2021> a <time> ;
		rdfs:label "20 Maggio 2021".
		<2020> a <time> ;
		rdfs:label "2020".
		<31_dicembre_2020> a <time> ;
		rdfs:label "31 dicembre 2020".
		<2020> a <time> ;
		rdfs:label "2020".
		<2021> a <time> ;
		rdfs:label "2021".
		<31_dicembre_2019> a <time> ;
		rdfs:label "31 dicembre 2019".
		<2020> a <time> ;
		rdfs:label "2020".
		<AGOSTO_2020> a <time> ;
		rdfs:label "AGOSTO 2020".
		<31.12.2020> a <time> ;
		rdfs:label "31.12.2020".
		<31.12.2018> a <time> ;
		rdfs:label "31.12.2018".
		<31.12.2019> a <time> ;
		rdfs:label "31.12.2019".
		<31.12.2019> a <time> ;
		rdfs:label "31.12.2019".
		<31.12.2019> a <time> ;
		rdfs:label "31.12.2019".
		<31.12.2018> a <time> ;
		rdfs:label "31.12.2018".
		<31.12.2018> a <time> ;
		rdfs:label "31.12.2018".
		<31.12.2019> a <time> ;
		rdfs:label "31.12.2019".
		<31.12.2020> a <time> ;
		rdfs:label "31.12.2020".
		<31.12.2021> a <time> ;
		rdfs:label "31.12.2021".
		<31.12.2020> a <time> ;
		rdfs:label "31.12.2020".
		<31.12.2021> a <time> ;
		rdfs:label "31.12.2021".
		<31.12.2019> a <time> ;
		rdfs:label "31.12.2019".
		<31.12.2018> a <time> ;
		rdfs:label "31.12.2018".
		<31.12.2019> a <time> ;
		rdfs:label "31.12.2019".
		<31.12.2020> a <time> ;
		rdfs:label "31.12.2020".
		<31.12.2021> a <time> ;
		rdfs:label "31.12.2021".
		<31.12.2021> a <time> ;
		rdfs:label "31.12.2021".
		
<https://explore.grasselliconsults.it/static/media/decreto_sostegni_tempo_determinato.3c4ec993.pdf> a studio:Documenti ;
	rdfs:label "tempo_determinato" ;
	dc:title "decreto_sostegni_tempo_determinato" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "decreto sostegni" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "12 I focus del Sole 24 Ore Giovedì 20 Maggio 2021 – N. 14 In questo solco si muove la legge di conversione del decreto Sostegni, che ha mantenuto inalterata (articolo 17) la possibilità per le imprese di rinnovare o prorogare per un periodo massimo di 12 mesi e per una sola volta i contratti a termine senza dover applicare le rigidissime causali previste dall’articolo 19, comma 1, del Dlgs 81/2015 dopo i primi 12 mesi del rapporto a termine (o in sede di primo rinnovo, se il contratto iniziale ha durata inferiore). [...] Tempo determinato Contratti a termine rinnovabili e prorogabili senza causali stero della Salute, di concerto con quelli dell’Economia e del Lavoro da emanarsi entro sessanta giorni dalla entrata in vigore della legge di conversione del decreto Sostegni. [...] Sanità, indennità una tantum ai somministrati Emergenza Covid Riconosciuta a chi è in servizio al 1° maggio proroga e rinnovo debbano intervenire entro il 31 dicembre 2021. [...] Confermata la possibilità per una sola volta ed entro un periodo massimo di 12 mesi Durata complessiva del rapporto di 24 mesi";
	studio:contenuti
		<Matteo_Prioschi>,<Giuseppe_Bulgarini>,<Maria_Carla>,<Milano>,<Milano>,<Fabio_Tamburini>,<Enrico>,<Mauro>,<20_Maggio_2021>,<maggio>,<2019>,<maggio>,<31_dicembre_2021>,<07-06-2013>,<8_milioni_di_euro>,<900_euro>,<2.400_euro>,<3.600_euro>.

	<Matteo_Prioschi> a <Person>,<FullName> ;
		rdfs:label "Matteo Prioschi".
		<Giuseppe_Bulgarini> a <Person>,<FullName> ;
		rdfs:label "Giuseppe Bulgarini".
		<Maria_Carla> a <Person>,<FirstName> ;
		rdfs:label "Maria Carla".
		<Milano> a <Location>,<GeoPoliticalEntity>,<Adm2> ;
		rdfs:label "Milano".
		<Milano> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Milano".
		<Fabio_Tamburini> a <Person>,<FullName> ;
		rdfs:label "Fabio Tamburini".
		<Enrico> a <Person>,<FirstName> ;
		rdfs:label "Enrico".
		<Mauro> a <Person>,<FirstName> ;
		rdfs:label "Mauro".
		<20_Maggio_2021> a <time> ;
		rdfs:label "20 Maggio 2021".
		<maggio> a <time> ;
		rdfs:label "maggio".
		<2019> a <time> ;
		rdfs:label "2019".
		<maggio> a <time> ;
		rdfs:label "maggio".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<07-06-2013> a <time> ;
		rdfs:label "07-06-2013".
		<8_milioni_di_euro> a "money" ;
		rdfs:label "8 milioni di euro".
		<900_euro> a "money" ;
		rdfs:label "900 euro".
		<2.400_euro> a "money" ;
		rdfs:label "2.400 euro".
		<3.600_euro> a "money" ;
		rdfs:label "3.600 euro".
		
<https://explore.grasselliconsults.it/static/media/decreto_sostegni_welfare.44e6440d.pdf> a studio:Documenti ;
	rdfs:label "welfare" ;
	dc:title "decreto_sostegni_welfare" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "decreto sostegni" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "I focus del Sole 24 Ore Giovedì 20 Maggio 2021 – N. 14 13 Dato che l’articolo 12-bis fa espresso riferimento al solo assegno di mantenimento, il Fondo non dovrebbe essere usato per aiutare chi deve pagare l’assegno spettante al (ex) coniuge lavoratori, quindi dovrebbe rivolgersi sia agli autonomi (da verificare quali effettivamente, tra gli iscritti a gestioni speciali Inps, gestione separata, casse di previdenza privatizzate) che ai dipendenti (da cui dovrebbero essere esclusi quelli del comparto pubblico per mancanza del requisito della riduzione, sospensione o cessazione dell’attività lavorativa da Covid). [...] Fanno eccezione i voucher rappresentativi di più beni e servizi che possono essere emessi a patto che il loro valore complessivo non superi il limite di 258,23 euro. [...] Come nel 2020, è stata raddoppiata la soglia di non imponibilità di beni e servizi offerti gratuitamente dall’azienda ai dipendenti [...] Tutto o niente Nella gestione di beni e servizi esenti da tassazione occorre tener presente che, se si supera la soglia, si perde l’agevolazione sull’intero importo, cioè l’esenzione non si può applicare per la parte di beni fino a 516,46 euro per tassare solo l’eccedente";
	studio:contenuti
		<Beni>,<Presidente_del_Consiglio>,<Fondo>,<Istituto_Nazionale_della_Previdenza_Sociale>,<San_Juan_de_Pasto>,<Beni>,<Stefano>,<Cate>,<20_Maggio_2021>,<2021>,<2020>,<25_marzo_2016>,<2020>,<ora>,<800_euro>,<10_milioni_di_euro>,<10_milioni_di_euro>,<516,46_euro>,<516,46_euro>,<8_euro>,<516,46_euro>,<2.300_euro>,<800_euro>,<516,46_euro>,<516,46_euro>,<258,23_euro>,<516,46_euro>,<258,23_euro>,<516,46_euro>,<Dieci_milioni_di_euro>,<516,46_euro>.

	<Beni> a <Location>,<GeoPoliticalEntity>,<Adm1> ;
		rdfs:label "Beni".
		<Presidente_del_Consiglio> a <OtherEntity>,<Title> ;
		rdfs:label "Presidente del Consiglio".
		<Fondo> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Fondo".
		<Istituto_Nazionale_della_Previdenza_Sociale> a <Organization>,<PublicInstitution> ;
		rdfs:label "Istituto Nazionale della Previdenza Sociale".
		<San_Juan_de_Pasto> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "San Juan de Pasto".
		<Beni> a <Person>,<FirstName> ;
		rdfs:label "Beni".
		<Stefano> a <Person> ;
		rdfs:label "Stefano".
		<Cate> a <Person>,<FirstName> ;
		rdfs:label "Cate".
		<20_Maggio_2021> a <time> ;
		rdfs:label "20 Maggio 2021".
		<2021> a <time> ;
		rdfs:label "2021".
		<2020> a <time> ;
		rdfs:label "2020".
		<25_marzo_2016> a <time> ;
		rdfs:label "25 marzo 2016".
		<2020> a <time> ;
		rdfs:label "2020".
		<ora> a <time> ;
		rdfs:label "ora".
		<800_euro> a "money" ;
		rdfs:label "800 euro".
		<10_milioni_di_euro> a "money" ;
		rdfs:label "10 milioni di euro".
		<10_milioni_di_euro> a "money" ;
		rdfs:label "10 milioni di euro".
		<516,46_euro> a "money" ;
		rdfs:label "51646 euro".
		<516,46_euro> a "money" ;
		rdfs:label "51646 euro".
		<8_euro> a "money" ;
		rdfs:label "8 euro".
		<516,46_euro> a "money" ;
		rdfs:label "51646 euro".
		<2.300_euro> a "money" ;
		rdfs:label "2.300 euro".
		<800_euro> a "money" ;
		rdfs:label "800 euro".
		<516,46_euro> a "money" ;
		rdfs:label "51646 euro".
		<516,46_euro> a "money" ;
		rdfs:label "51646 euro".
		<258,23_euro> a "money" ;
		rdfs:label "25823 euro".
		<516,46_euro> a "money" ;
		rdfs:label "51646 euro".
		<258,23_euro> a "money" ;
		rdfs:label "25823 euro".
		<516,46_euro> a "money" ;
		rdfs:label "51646 euro".
		<Dieci_milioni_di_euro> a "money" ;
		rdfs:label "Dieci milioni di euro".
		<516,46_euro> a "money" ;
		rdfs:label "51646 euro".
		
<https://explore.grasselliconsults.it/static/media/flessibilità_e_garanzie.f06da06f.pdf> a studio:Documenti ;
	rdfs:label "flessibilità_e_garanzie" ;
	dc:title "flessibilità_e_garanzie" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "decreto sostegni" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "12 I focus del Sole 24 Ore Giovedì 1 Aprile 2021 – N. 8 Con il decreto Sostegni è arrivato anche il quarto rinvio della possibilità di pro- rogare e rinnovare i contratti a termine senza causale. [...] Effetti del decreto Dignità Proviamo a ricapitolare. [...] Per i lavoratori fragili che non possono lavorare in modo agile, la tutela della malattia si estende fino al 30 giugno 2021 e con effetto retroattivo dal 1° marzo. [...] La nota dell’Ispettorato L’ispettorato nazionale del lavoro, con una nota del 16 settembre 2020, ha chiarito che la deroga investiva non solo la causale, ma anche il numero massimo di proroghe e il rispetto dei periodi cuscinetto tra un contratto e l’altro.";
	studio:contenuti
		<Istituto_Nazionale_della_Previdenza_Sociale>,<Aldo_Bottini>,<Enzo_De_Fusco>,<1_Aprile_2021>,<marzo_2020>,<2/5>,<15_ottobre_2020>,<gennaio>,<28_febbraio_2021>,<31_dicembre_2020>,<Ora>,<30_giugno_2021>,<marzo>,<31_dicembre_2020>,<marzo>,<30_giugno_2021>,<marzo>,<marzo>,<31_dicembre_2021>,<23_febbraio_2020>,<30_agosto_2020>,<Agosto>,<31_dicembre_2020>,<24_febbraio_2020>,<16_settembre_2020>,<31_marzo_2021>,<15_agosto_2020>,<31_marzo_2021>,<31_dicembre_2021>,<23_marzo_2021>,<16_settembre_2021>,<2022>.

	<Istituto_Nazionale_della_Previdenza_Sociale> a <Organization>,<PublicInstitution> ;
		rdfs:label "Istituto Nazionale della Previdenza Sociale".
		<Aldo_Bottini> a <Person>,<FullName> ;
		rdfs:label "Aldo Bottini".
		<Enzo_De_Fusco> a <Person>,<FullName> ;
		rdfs:label "Enzo De Fusco".
		<1_Aprile_2021> a <time> ;
		rdfs:label "1 Aprile 2021".
		<marzo_2020> a <time> ;
		rdfs:label "marzo 2020".
		<2/5> a <time> ;
		rdfs:label "2/5".
		<15_ottobre_2020> a <time> ;
		rdfs:label "15 ottobre 2020".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		<28_febbraio_2021> a <time> ;
		rdfs:label "28 febbraio 2021".
		<31_dicembre_2020> a <time> ;
		rdfs:label "31 dicembre 2020".
		<Ora> a <time> ;
		rdfs:label "Ora".
		<30_giugno_2021> a <time> ;
		rdfs:label "30 giugno 2021".
		<marzo> a <time> ;
		rdfs:label "marzo".
		<31_dicembre_2020> a <time> ;
		rdfs:label "31 dicembre 2020".
		<marzo> a <time> ;
		rdfs:label "marzo".
		<30_giugno_2021> a <time> ;
		rdfs:label "30 giugno 2021".
		<marzo> a <time> ;
		rdfs:label "marzo".
		<marzo> a <time> ;
		rdfs:label "marzo".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<23_febbraio_2020> a <time> ;
		rdfs:label "23 febbraio 2020".
		<30_agosto_2020> a <time> ;
		rdfs:label "30 agosto 2020".
		<Agosto> a <time> ;
		rdfs:label "Agosto".
		<31_dicembre_2020> a <time> ;
		rdfs:label "31 dicembre 2020".
		<24_febbraio_2020> a <time> ;
		rdfs:label "24 febbraio 2020".
		<16_settembre_2020> a <time> ;
		rdfs:label "16 settembre 2020".
		<31_marzo_2021> a <time> ;
		rdfs:label "31 marzo 2021".
		<15_agosto_2020> a <time> ;
		rdfs:label "15 agosto 2020".
		<31_marzo_2021> a <time> ;
		rdfs:label "31 marzo 2021".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<23_marzo_2021> a <time> ;
		rdfs:label "23 marzo 2021".
		<16_settembre_2021> a <time> ;
		rdfs:label "16 settembre 2021".
		<2022> a <time> ;
		rdfs:label "2022".
		
<https://explore.grasselliconsults.it/static/media/fondo_perduto_aiuti_plafond_10_milioni.df626a1c.pdf> a studio:Documenti ;
	rdfs:label "aiuti_plafond_10_milioni" ;
	dc:title "fondo_perduto_aiuti_plafond_10_milioni" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "fondo perduto" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "4 I focus del Sole 24 Ore Giovedì 1 Aprile 2021 – N. 8 Il decreto Sostegni non ha modificato tali regole, limitandosi a incrementare la dotazione finanziaria disponibile che passa da 1 a 2,5 miliardi di euro per quest’anno e a precisare che si tratta di un aiuto di Stato soggetto all’autorizzazione della Commissione europea nell’ambito delle misure temporanee per fronteggiare il Covid-19. [...] In questa fase le imprese si limitano a fare la domanda per ottenere il contributo a fondo perduto. [...] Impresa unica per il temporary framework Il decreto chiarisce anche come devono comportarsi le imprese che fanno parte di un gruppo per il calcolo dei massimali utilizzati. Ai fini del Quadro temporaneo le imprese devono applicare la definizione di «impresa unica» contenuta nella disciplina europea sugli aiuti de minimis.";
	studio:contenuti
		<Commissione_Europea>,<Istituto_Nazionale_della_Previdenza_Sociale>,<Roberto>,<Unione_Europea>,<Matteo_Prioschi>,<Fondo>,<Alessandro_Amitrano>,<Rossella_Accoto>,<PMI_Group>,<1_Aprile_2021>,<2019>,<2020>,<gennaio_2021>,<novembre>,<dicembre>,<2020>,<26_marzo_2021>,<2019>,<24_dicembre_2013>,<una_o>,<due_o>,<50mila_euro>,<2,5_miliardi_di_euro>,<3mila_euro>,<150mila_euro>,<10_milioni_di_euro>,<1,8_milioni_di_euro>,<10_milioni_di_euro>.

	<Commissione_Europea> a <Organization>,<Government> ;
		rdfs:label "Commissione Europea".
		<Istituto_Nazionale_della_Previdenza_Sociale> a <Organization>,<PublicInstitution> ;
		rdfs:label "Istituto Nazionale della Previdenza Sociale".
		<Roberto> a <Person> ;
		rdfs:label "Roberto".
		<Unione_Europea> a <Location>,<GeoPoliticalEntity> ;
		rdfs:label "Unione Europea".
		<Matteo_Prioschi> a <Person>,<FullName> ;
		rdfs:label "Matteo Prioschi".
		<Fondo> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Fondo".
		<Alessandro_Amitrano> a <Person>,<FullName> ;
		rdfs:label "Alessandro Amitrano".
		<Rossella_Accoto> a <Person>,<FullName> ;
		rdfs:label "Rossella Accoto".
		<PMI_Group> a <Organization>,<Company>,<FinancialCompany> ;
		rdfs:label "PMI Group".
		<1_Aprile_2021> a <time> ;
		rdfs:label "1 Aprile 2021".
		<2019> a <time> ;
		rdfs:label "2019".
		<2020> a <time> ;
		rdfs:label "2020".
		<gennaio_2021> a <time> ;
		rdfs:label "gennaio 2021".
		<novembre> a <time> ;
		rdfs:label "novembre".
		<dicembre> a <time> ;
		rdfs:label "dicembre".
		<2020> a <time> ;
		rdfs:label "2020".
		<26_marzo_2021> a <time> ;
		rdfs:label "26 marzo 2021".
		<2019> a <time> ;
		rdfs:label "2019".
		<24_dicembre_2013> a <time> ;
		rdfs:label "24 dicembre 2013".
		<una_o> a <time> ;
		rdfs:label "una o".
		<due_o> a <time> ;
		rdfs:label "due o".
		<50mila_euro> a "money" ;
		rdfs:label "50mila euro".
		<2,5_miliardi_di_euro> a "money" ;
		rdfs:label "25 miliardi di euro".
		<3mila_euro> a "money" ;
		rdfs:label "3mila euro".
		<150mila_euro> a "money" ;
		rdfs:label "150mila euro".
		<10_milioni_di_euro> a "money" ;
		rdfs:label "10 milioni di euro".
		<1,8_milioni_di_euro> a "money" ;
		rdfs:label "18 milioni di euro".
		<10_milioni_di_euro> a "money" ;
		rdfs:label "10 milioni di euro".
		
<https://explore.grasselliconsults.it/static/media/grandi_crisi.14490940.pdf> a studio:Documenti ;
	rdfs:label "grandi_crisi" ;
	dc:title "grandi_crisi" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "covid" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "I focus del Sole 24 Ore Giovedì 1 Aprile 2021 – N. 8 9 P revisti sostanziosi rifinanziamenti per limitare gli impatti dell’emer- genza epidemiologica da Covid-19 sulle problematiche occupazionali dei dipendenti ex Ilva e dei dipendenti del settore aeroportuale. L’articolo 9 del Dl 41/21 (Dl Sostegni) si occupa di due dossier particolarmente delicati già presenti sul tavolo del governo: la gestione dei dipendenti del Gruppo Ilva in amministrazione straordinaria e del settore aeroportuale. [...] 1 gli ammortizzatori sociali in deroga; 2 le proroghe di Cigs per cessazione dell’attività; 3 le iniziative per l’esercizio del diritto-dovere all’istruzione e alla formazione; 4 l’attività di formazione nell’esercizio dell'apprendistato; 5 le borse tirocinio formativo a favore di giovani; 6 gli incentivi per il reimpiego di lavoratori over 50; 7 le agevolazioni per progetti di riduzione dell’orario di lavoro; 8 l’intervento in favore dei lavoratori esodati; 9 gli incentivi e le iniziative a favore dei lavoratori socialmente utili; 10 gli incentivi per il prepensionamento dei giornalisti. [...] Il riferimento è alla cassa in deroga di massimo 28 settimane collocate nel periodo tra il 1° aprile 2021 e il 31 dicembre 2021.";
	studio:contenuti
		<Fondo>,<Ilva>,<Istituto_Nazionale_della_Previdenza_Sociale>,<Cristian_Valsiglio>,<governo>,<Roberto>,<Taranto>,<1_Aprile_2021>,<2008>,<2021>,<30_giugno_2021>,<23_marzo_2021>,<gennaio>,<aprile>,<31_dicembre_2021>,<400_milioni_di_euro>,<80_milioni_di_euro>,<186,7_milioni_di_euro>,<19_milioni_di_euro>,<186,7_milioni_di_euro>.

	<Fondo> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Fondo".
		<Ilva> a <Organization>,<Company>,<MaterialsCompany>,<MineralResources> ;
		rdfs:label "Ilva".
		<Istituto_Nazionale_della_Previdenza_Sociale> a <Organization>,<PublicInstitution> ;
		rdfs:label "Istituto Nazionale della Previdenza Sociale".
		<Cristian_Valsiglio> a <Person>,<FullName> ;
		rdfs:label "Cristian Valsiglio".
		<governo> a <Organization>,<Government> ;
		rdfs:label "governo".
		<Roberto> a <Person> ;
		rdfs:label "Roberto".
		<Taranto> a <Location>,<GeoPoliticalEntity>,<Adm2> ;
		rdfs:label "Taranto".
		<1_Aprile_2021> a <time> ;
		rdfs:label "1 Aprile 2021".
		<2008> a <time> ;
		rdfs:label "2008".
		<2021> a <time> ;
		rdfs:label "2021".
		<30_giugno_2021> a <time> ;
		rdfs:label "30 giugno 2021".
		<23_marzo_2021> a <time> ;
		rdfs:label "23 marzo 2021".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		<aprile> a <time> ;
		rdfs:label "aprile".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<400_milioni_di_euro> a "money" ;
		rdfs:label "400 milioni di euro".
		<80_milioni_di_euro> a "money" ;
		rdfs:label "80 milioni di euro".
		<186,7_milioni_di_euro> a "money" ;
		rdfs:label "1867 milioni di euro".
		<19_milioni_di_euro> a "money" ;
		rdfs:label "19 milioni di euro".
		<186,7_milioni_di_euro> a "money" ;
		rdfs:label "1867 milioni di euro".
		
<https://explore.grasselliconsults.it/static/media/il_fondo_perduto_ricavi_fino_a_100_mila.ca93456b.pdf> a studio:Documenti ;
	rdfs:label "ricavi_fino_a_100_mila" ;
	dc:title "il_fondo_perduto_ricavi_fino_a_100_mila" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "fondo perduto" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "I focus del Sole 24 Ore Giovedì 1 Aprile 2021 – N. 8 3 In sintesi, infatti, si tratta di applicare lo specifico coefficiente dimensionale fissato dalla norma alla differenza registrata tra il fatturato medio mensile del 2020 e il fatturato medio mensile del 2019. [...] È evidente come il sistema – commisurando l’ammontare del ristoro alla diminuzione del fatturato – privilegi l’esigenza di provvedere all'erogazione delle somme con tempestività rispetto a quella di determinare con precisione la corrispondenza tra danno subito e contributo spettante, facendo riferimento, ad esempio, a parametri basati sul calo del reddito. [...] Nel rispetto di tutte le condizioni imposte dall’articolo 1 del Dl 41/21 possono beneficiare del contributo a fondo perduto tutte le imprese agricole titolari di reddito d’impresa e di reddito agrario. [...] 150mila Il contributo esigibile L’importo del contributo non può superare 150mila euro.";
	studio:contenuti
		<IVA>,<Caso>,<Andrea_Dili>,<Scor>,<Francesco>,<1_Aprile_2021>,<autunno>,<2020>,<2019>,<31_dicembre_2018>,<gennaio_2019>,<novembre_2019>,<2019>,<agosto_2019>,<31_dicembre_2019>,<dicembre_2019>,<23_marzo_2021>,<2019>,<2020>,<2019>,<23_marzo_2021>,<2019>,<2020>,<100mila_euro>,<400mila_euro>,<2mila_euro>,<150mila_euro>,<100mila_euro>,<7mila_euro>,<7mila_euro>,<100mila_euro>,<5.000_€>,<15.000_€>,<50.000_€>,<100.000_€>,<500.000_€>,<2.000_euro>,<150mila_euro>.

	<IVA> a <OtherEntity>,<IndexName>,<EconomicIndexName> ;
		rdfs:label "IVA".
		<Caso> a <Person>,<LastName> ;
		rdfs:label "Caso".
		<Andrea_Dili> a <Person>,<FullName> ;
		rdfs:label "Andrea Dili".
		<Scor> a <Organization>,<Company>,<FinancialCompany>,<Insurance> ;
		rdfs:label "Scor".
		<Francesco> a <Person>,<FirstName> ;
		rdfs:label "Francesco".
		<1_Aprile_2021> a <time> ;
		rdfs:label "1 Aprile 2021".
		<autunno> a <time> ;
		rdfs:label "autunno".
		<2020> a <time> ;
		rdfs:label "2020".
		<2019> a <time> ;
		rdfs:label "2019".
		<31_dicembre_2018> a <time> ;
		rdfs:label "31 dicembre 2018".
		<gennaio_2019> a <time> ;
		rdfs:label "gennaio 2019".
		<novembre_2019> a <time> ;
		rdfs:label "novembre 2019".
		<2019> a <time> ;
		rdfs:label "2019".
		<agosto_2019> a <time> ;
		rdfs:label "agosto 2019".
		<31_dicembre_2019> a <time> ;
		rdfs:label "31 dicembre 2019".
		<dicembre_2019> a <time> ;
		rdfs:label "dicembre 2019".
		<23_marzo_2021> a <time> ;
		rdfs:label "23 marzo 2021".
		<2019> a <time> ;
		rdfs:label "2019".
		<2020> a <time> ;
		rdfs:label "2020".
		<2019> a <time> ;
		rdfs:label "2019".
		<23_marzo_2021> a <time> ;
		rdfs:label "23 marzo 2021".
		<2019> a <time> ;
		rdfs:label "2019".
		<2020> a <time> ;
		rdfs:label "2020".
		<100mila_euro> a "money" ;
		rdfs:label "100mila euro".
		<400mila_euro> a "money" ;
		rdfs:label "400mila euro".
		<2mila_euro> a "money" ;
		rdfs:label "2mila euro".
		<150mila_euro> a "money" ;
		rdfs:label "150mila euro".
		<100mila_euro> a "money" ;
		rdfs:label "100mila euro".
		<7mila_euro> a "money" ;
		rdfs:label "7mila euro".
		<7mila_euro> a "money" ;
		rdfs:label "7mila euro".
		<100mila_euro> a "money" ;
		rdfs:label "100mila euro".
		<5.000_€> a "money" ;
		rdfs:label "5.000 €".
		<15.000_€> a "money" ;
		rdfs:label "15.000 €".
		<50.000_€> a "money" ;
		rdfs:label "50.000 €".
		<100.000_€> a "money" ;
		rdfs:label "100.000 €".
		<500.000_€> a "money" ;
		rdfs:label "500.000 €".
		<2.000_euro> a "money" ;
		rdfs:label "2.000 euro".
		<150mila_euro> a "money" ;
		rdfs:label "150mila euro".
		
<https://explore.grasselliconsults.it/static/media/il_fondo_perduto_ricavi_inferioni_a_10_milioni.63fb9b87.pdf> a studio:Documenti ;
	rdfs:label "ricavi_inferioni_a_10_milioni" ;
	dc:title "il_fondo_perduto_ricavi_inferioni_a_10_milioni" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "fondo perduto" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "2 I focus del Sole 24 Ore Giovedì 1 Aprile 2021 – N. 8 Il procedimento di calcolo del contributo dovuto è molto semplice: basterà applicare il coefficiente dimensionale previsto dalla norma (20-60%) alla differenza tra il fatturato medio mensile del 2019 e quello del 2020, garantendo un ammontare minimo di mille o 2mila euro, rispettivamente per le Persone_Fisiche e per i soggetti diversi dalle Persone_Fisiche, e fissando un tetto di 150mila euro. [...] Tale opzione, che è irrevocabile e dovrà riguardare l'intero importo del contributo non essendone ammesso il frazionamento, dovrà essere espressa in sede di invio di apposita istanza alle Entrate, domanda che dovrà essere presentata telematicamente, anche avvalendosi di un intermediario, a partire dal 30 marzo e fino al 28 maggio 2021, seguendo le istruzioni declinate dal provvedimento del direttore delle Entrate 77923/21.O [...] L’opzione, che è irrevocabile e dovrà riguardare l'intero importo del contributo non essendone ammesso il frazionamento, dovrà essere espressa in sede di invio di apposita istanza all’agenzia delle Entrate. [...] L’importo minimo del beneficio oscilla tra mille o 2mila euro rispettivamente per le Persone_Fisiche e per gli altri soggetti";
	studio:contenuti
		<IVA>,<Andrea_Dili>,<1_Aprile_2021>,<2019>,<2020>,<2019>,<2020>,<28_maggio_2021>,<31_DICEMBRE_2018>,<30_NOVEMBRE_2019>,<1_DICEMBRE_2019>,<23_MARZO_2021>,<2019>,<2020>,<2020>,<maggio_2020>,<autunno>,<ottobre>,<novembre>,<23_marzo_2021>,<24_marzo_2021>,<2020>,<2019>,<gennaio>,<2mila_euro>,<150mila_euro>,<10_milioni_di_euro>,<1.000_euro>,<2.000_euro>,<1.000_euro>,<2.000_euro>,<150.000_euro>,<2mila_euro>,<10_milioni_di_euro>.

	<IVA> a <OtherEntity>,<IndexName>,<EconomicIndexName> ;
		rdfs:label "IVA".
		<Andrea_Dili> a <Person>,<FullName> ;
		rdfs:label "Andrea Dili".
		<1_Aprile_2021> a <time> ;
		rdfs:label "1 Aprile 2021".
		<2019> a <time> ;
		rdfs:label "2019".
		<2020> a <time> ;
		rdfs:label "2020".
		<2019> a <time> ;
		rdfs:label "2019".
		<2020> a <time> ;
		rdfs:label "2020".
		<28_maggio_2021> a <time> ;
		rdfs:label "28 maggio 2021".
		<31_DICEMBRE_2018> a <time> ;
		rdfs:label "31 DICEMBRE 2018".
		<30_NOVEMBRE_2019> a <time> ;
		rdfs:label "30 NOVEMBRE 2019".
		<1_DICEMBRE_2019> a <time> ;
		rdfs:label "1 DICEMBRE 2019".
		<23_MARZO_2021> a <time> ;
		rdfs:label "23 MARZO 2021".
		<2019> a <time> ;
		rdfs:label "2019".
		<2020> a <time> ;
		rdfs:label "2020".
		<2020> a <time> ;
		rdfs:label "2020".
		<maggio_2020> a <time> ;
		rdfs:label "maggio 2020".
		<autunno> a <time> ;
		rdfs:label "autunno".
		<ottobre> a <time> ;
		rdfs:label "ottobre".
		<novembre> a <time> ;
		rdfs:label "novembre".
		<23_marzo_2021> a <time> ;
		rdfs:label "23 marzo 2021".
		<24_marzo_2021> a <time> ;
		rdfs:label "24 marzo 2021".
		<2020> a <time> ;
		rdfs:label "2020".
		<2019> a <time> ;
		rdfs:label "2019".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		<2mila_euro> a "money" ;
		rdfs:label "2mila euro".
		<150mila_euro> a "money" ;
		rdfs:label "150mila euro".
		<10_milioni_di_euro> a "money" ;
		rdfs:label "10 milioni di euro".
		<1.000_euro> a "money" ;
		rdfs:label "1.000 euro".
		<2.000_euro> a "money" ;
		rdfs:label "2.000 euro".
		<1.000_euro> a "money" ;
		rdfs:label "1.000 euro".
		<2.000_euro> a "money" ;
		rdfs:label "2.000 euro".
		<150.000_euro> a "money" ;
		rdfs:label "150.000 euro".
		<2mila_euro> a "money" ;
		rdfs:label "2mila euro".
		<10_milioni_di_euro> a "money" ;
		rdfs:label "10 milioni di euro".
		
<https://explore.grasselliconsults.it/static/media/Incentivi_tecnologia_Fisco.2d65876b.pdf> a studio:Documenti ;
	rdfs:label "Incentivi tecnologia Fisco" ;
	dc:title "Incentivi tecnologia Fisco" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "fisco" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ; ;

	dc:abstract "In Italia le agevolazioni hanno frenato la caduta delle vendite Ma ora ne servono altre per affrontare il futuro di Paolo Lorenzi S e il 2020 è stato un anno durissimo per l’industria automobilistica, il 2021 appare ancora avvolto da una cappa d’incertezza. Mentre la pandemia sembra allentare la presa, il settore si divide sulla ripresa. Su una cosa, però, sono tutti d’accordo, gli incentivi hanno frenato il crollo delle vendite innescato dalla crisi sanitaria. [...] In questo caso il segno s’inverte e la realtà appare in tutta la sua evidenza: il settore resta in rosso,perunbuon22%.";
	studio:contenuti
		<Italia>,<Unione_Europea>,<Germania>,<Francia>,<Spagna>,<Oliver_Zipse>,<2019_2020_2021>,<Europa>,<PIL>,<Jato>,<Corriere_della_Sera>,<Association_des_constructeurs_européens_d'automobiles>,<Andrea_Cardinali>,<Dati>,<Fonte>,<Fonte>,<mar>,<Renault>,<Paolo_Lorenzi>,<Stefani_Cosentino>,<Federauto>,<Volvo>,<Eric_Pasquier>,<BMW>,<Maserati>,<mercato_Italianodella>,<Massimo_Di_Silvestre>,<LUNEDÌ_31.05.2021>,<ora>,<2020>,<Oggi>,<estate>,<oggi>,<2020>,<febbraio>,<aprile>,<2020>,<2021>,<oggi>,<1990>,<entro_dicembre_del_2020>,<2025>,<2030>,<aprile>,<2021>,<maggio_2019>,<mag>,<ago>,<set>,<ott>,<nov>,<dic>,<gen>,<mar>,<apr>,<2,5_miliardi_di_euro>,<95_euro>.

	<Italia> a <Location>,<GeoPoliticalEntity>,<Country> ;
		rdfs:label "Italia".
		<Unione_Europea> a <Location>,<GeoPoliticalEntity> ;
		rdfs:label "Unione Europea".
		<Germania> a <Location>,<GeoPoliticalEntity>,<Country> ;
		rdfs:label "Germania".
		<Francia> a <Location>,<GeoPoliticalEntity>,<Country> ;
		rdfs:label "Francia".
		<Spagna> a <Location>,<GeoPoliticalEntity>,<Country> ;
		rdfs:label "Spagna".
		<Oliver_Zipse> a <Person>,<FullName> ;
		rdfs:label "Oliver Zipse".
		<2019_2020_2021> a <Id>,<PhoneNumber> ;
		rdfs:label "2019 2020 2021".
		<Europa> a <Location>,<GeoPoliticalEntity>,<Continent> ;
		rdfs:label "Europa".
		<PIL> a <OtherEntity>,<IndexName>,<EconomicIndexName> ;
		rdfs:label "PIL".
		<Jato> a <Person>,<LastName> ;
		rdfs:label "Jato".
		<Corriere_della_Sera> a <Product>,<CulturalProduct>,<Printing>,<Newspaper> ;
		rdfs:label "Corriere della Sera".
		<Association_des_constructeurs_européens_d'automobiles> a <Organization>,<Institute>,<LaborUnion> ;
		rdfs:label "Association des constructeurs européens d'automobiles".
		<Andrea_Cardinali> a <Person>,<FullName> ;
		rdfs:label "Andrea Cardinali".
		<Dati> a <Person>,<LastName> ;
		rdfs:label "Dati".
		<Fonte> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Fonte".
		<Fonte> a <Person>,<LastName> ;
		rdfs:label "Fonte".
		<mar> a <Person> ;
		rdfs:label "mar".
		<Renault> a <Organization>,<Company>,<IndustrialCompany> ;
		rdfs:label "Renault".
		<Paolo_Lorenzi> a <Person>,<FullName> ;
		rdfs:label "Paolo Lorenzi".
		<Stefani_Cosentino> a <Person>,<FullName> ;
		rdfs:label "Stefani Cosentino".
		<Federauto> a <Organization>,<Company>,<IndustrialCompany> ;
		rdfs:label "Federauto".
		<Volvo> a <Organization>,<Company>,<ConsumerGoodsCompany>,<Discretionary>,<AutomobileCompany> ;
		rdfs:label "Volvo".
		<Eric_Pasquier> a <Person>,<FullName> ;
		rdfs:label "Eric Pasquier".
		<BMW> a <Organization>,<Company>,<ConsumerGoodsCompany>,<Discretionary>,<AutomobileCompany> ;
		rdfs:label "BMW".
		<Maserati> a <Organization>,<Company>,<IndustrialCompany> ;
		rdfs:label "Maserati".
		<mercato_Italianodella> a <Event> ;
		rdfs:label "mercato Italianodella".
		<Massimo_Di_Silvestre> a <Person>,<FullName> ;
		rdfs:label "Massimo Di Silvestre".
		<LUNEDÌ_31.05.2021> a <time> ;
		rdfs:label "LUNEDÌ 31.05.2021".
		<ora> a <time> ;
		rdfs:label "ora".
		<2020> a <time> ;
		rdfs:label "2020".
		<Oggi> a <time> ;
		rdfs:label "Oggi".
		<estate> a <time> ;
		rdfs:label "estate".
		<oggi> a <time> ;
		rdfs:label "oggi".
		<2020> a <time> ;
		rdfs:label "2020".
		<febbraio> a <time> ;
		rdfs:label "febbraio".
		<aprile> a <time> ;
		rdfs:label "aprile".
		<2020> a <time> ;
		rdfs:label "2020".
		<2021> a <time> ;
		rdfs:label "2021".
		<oggi> a <time> ;
		rdfs:label "oggi".
		<1990> a <time> ;
		rdfs:label "1990".
		<entro_dicembre_del_2020> a <time> ;
		rdfs:label "entro dicembre del 2020".
		<2025> a <time> ;
		rdfs:label "2025".
		<2030> a <time> ;
		rdfs:label "2030".
		<aprile> a <time> ;
		rdfs:label "aprile".
		<2021> a <time> ;
		rdfs:label "2021".
		<maggio_2019> a <time> ;
		rdfs:label "maggio 2019".
		<mag> a <time> ;
		rdfs:label "mag".
		<ago> a <time> ;
		rdfs:label "ago".
		<set> a <time> ;
		rdfs:label "set".
		<ott> a <time> ;
		rdfs:label "ott".
		<nov> a <time> ;
		rdfs:label "nov".
		<dic> a <time> ;
		rdfs:label "dic".
		<gen> a <time> ;
		rdfs:label "gen".
		<mar> a <time> ;
		rdfs:label "mar".
		<apr> a <time> ;
		rdfs:label "apr".
		<2,5_miliardi_di_euro> a "money" ;
		rdfs:label "25 miliardi di euro".
		<95_euro> a "money" ;
		rdfs:label "95 euro".
		
<https://explore.grasselliconsults.it/static/media/mutui_giovani.50c4f171.pdf> a studio:Documenti ;
	rdfs:label "mutui giovani" ;
	dc:title "mutui giovani" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "mutuo" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ; ;

	dc:abstract "Prezzo min-max metro quadrato Con il fondo [...] Immobili usati abitabili, mutuo 100% a trent’anni Fonte: elaborazione L’Economia del Corriere su dati Fiaip Il risparmio fiscale sull’acquisto di una casa da 200 mila euro comprata da privato 2.400 euro [...] I mutui per i giovani, Lo sconto va da 3.600 euro a oltre 11 mila se la casa è in costruzione [...] Nel 2020 Intesa Sanpaolo ha stipulato mutui per circa 14 miliardi di euro, oltre il 20%per gli under 35 e il 35% di questi finanziamenti era ad alto Ltv.";
	studio:contenuti
		<Fondo>,<Galvani_Marconi_Mazzini>,<Banca_Intesa>,<Piazza>,<IVA>,<Kassala>,<Bologna_F._C.>,<Prezzo>,<max>,<Olympique_de_Lyon>,<Cermenate>,<San_Paolo_di_Civitate>,<Euclide_Coppedè_Pinciano>,<Roma>,<Ipotesti>,<Cavour>,<Gino_Pagliuca>,<Bologna>,<Bologna>,<Napoli>,<S._S._C._Napoli>,<Napoli>,<Mario>,<Liberty_Ventures>,<Margherita>,<Rita>,<Italia>,<Fabio_Paone>,<Andrea_Lecce>,<NA>,<Orsola>,<S.Vitale>,<Malpighi>,<Unione_Europea>,<Capodimonte>,<Emanuele>,<Tor>,<Monte_Mario>,<Cinecittà>,<Ostiense>,<Castel_Sant'Angelo>,<Cola_di_Rienzo>,<Stato_Così>,<Corriere_della_Sera>,<Bolivar>,<Bolivar>,<Gioia>,<Firenze>,<Firenze>,<Monte_Nero>,<Solari>,<Genova>,<Garibaldi>,<Garibaldi>,<Vercelli>,<Lorenteggio_Barona>,<Sturla>,<Albaro>,<Nervi>,<Carignano>,<Torino>,<CIT_Group,_Inc.>,<Vanchiglia_Sassi>,<Nizza>,<San_Paolo>,<Pozzo>,<San_Donato_di_Lecce>,<Chiaravalle>,<Milano>,<Milano>,<Sandra>,<Fonte>,<Trent>,<Rossa>,<Padova>,<Roma>,<Muggiano_Olmi>,<Maggi>,<GRECO>,<Greco>,<Istria>,<stadio_San_Siro>,<Udine>,<Pavese>,<Novara>,<Baggio>,<31.05.2021>,<30_giugno_2022>,<2013>,<ora>,<LUNEDÌ_31.05.2021>,<oggi>,<2020>,<200_mila_euro>,<2.400_euro>,<200_mila_euro>,<8.600_euro>,<200_mila_euro>,<500_euro>,<3.600_euro>,<250_mila_euro>,<3.600_euro>,<11.225_euro>,<250_mila_euro>,<250mila_euro>,<250_mila_euro>,<949_euro>,<250mila_euro>,<600_euro>,<40mila_euro>,<250_mila_euro>,<250.000_euro>,<40.000_euro>,<14_miliardi_di_euro>.

	<Fondo> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Fondo".
		<Galvani_Marconi_Mazzini> a <Person>,<FullName> ;
		rdfs:label "Galvani Marconi Mazzini".
		<Banca_Intesa> a <Organization>,<Company>,<FinancialCompany>,<BankingCompany>,<BankingServices>,<Bank> ;
		rdfs:label "Banca Intesa".
		<Piazza> a <Person>,<LastName> ;
		rdfs:label "Piazza".
		<IVA> a <OtherEntity>,<IndexName>,<EconomicIndexName> ;
		rdfs:label "IVA".
		<Kassala> a <Location>,<GeoPoliticalEntity> ;
		rdfs:label "Kassala".
		<Bologna_F._C.> a <Organization>,<SportsOrganization>,<SportsTeam> ;
		rdfs:label "Bologna F. C.".
		<Prezzo> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Prezzo".
		<max> a <Person> ;
		rdfs:label "max".
		<Olympique_de_Lyon> a <Organization>,<SportsOrganization>,<SportsTeam> ;
		rdfs:label "Olympique de Lyon".
		<Cermenate> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Cermenate".
		<San_Paolo_di_Civitate> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "San Paolo di Civitate".
		<Euclide_Coppedè_Pinciano> a <Person>,<FullName> ;
		rdfs:label "Euclide Coppedè Pinciano".
		<Roma> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Roma".
		<Ipotesti> a <Location>,<GeographicalEntity>,<WaterForm>,<Lake> ;
		rdfs:label "Ipotesti".
		<Cavour> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Cavour".
		<Gino_Pagliuca> a <Person>,<FullName> ;
		rdfs:label "Gino Pagliuca".
		<Bologna> a <Location>,<GeoPoliticalEntity>,<Adm2> ;
		rdfs:label "Bologna".
		<Bologna> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Bologna".
		<Napoli> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Napoli".
		<S._S._C._Napoli> a <Organization>,<SportsOrganization>,<SportsTeam> ;
		rdfs:label "S. S. C. Napoli".
		<Napoli> a <Location>,<GeoPoliticalEntity>,<Adm2> ;
		rdfs:label "Napoli".
		<Mario> a <Person>,<FirstName> ;
		rdfs:label "Mario".
		<Liberty_Ventures> a <Organization>,<Institute>,<EnterpriseAssociation> ;
		rdfs:label "Liberty Ventures".
		<Margherita> a <Person>,<FirstName> ;
		rdfs:label "Margherita".
		<Rita> a <Person>,<FirstName> ;
		rdfs:label "Rita".
		<Italia> a <Location>,<GeoPoliticalEntity>,<Country> ;
		rdfs:label "Italia".
		<Fabio_Paone> a <Person>,<FullName> ;
		rdfs:label "Fabio Paone".
		<Andrea_Lecce> a <Person>,<FullName> ;
		rdfs:label "Andrea Lecce".
		<NA> a <Organization> ;
		rdfs:label "NA".
		<Orsola> a <Person>,<FirstName> ;
		rdfs:label "Orsola".
		<S.Vitale> a <Person>,<FullName> ;
		rdfs:label "S.Vitale".
		<Malpighi> a <Person>,<LastName> ;
		rdfs:label "Malpighi".
		<Unione_Europea> a <Location>,<GeoPoliticalEntity> ;
		rdfs:label "Unione Europea".
		<Capodimonte> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Capodimonte".
		<Emanuele> a <Person>,<LastName> ;
		rdfs:label "Emanuele".
		<Tor> a <Person> ;
		rdfs:label "Tor".
		<Monte_Mario> a <Location>,<GeographicalEntity>,<LandForm>,<Mountain> ;
		rdfs:label "Monte Mario".
		<Cinecittà> a <Location>,<Facility> ;
		rdfs:label "Cinecittà".
		<Ostiense> a <Location>,<GeoPoliticalEntity>,<District> ;
		rdfs:label "Ostiense".
		<Castel_Sant'Angelo> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Castel Sant'Angelo".
		<Cola_di_Rienzo> a <Person>,<FullName> ;
		rdfs:label "Cola di Rienzo".
		<Stato_Così> a <Location>,<GeoPoliticalEntity>,<Country> ;
		rdfs:label "Stato Così".
		<Corriere_della_Sera> a <Product>,<CulturalProduct>,<Printing>,<Newspaper> ;
		rdfs:label "Corriere della Sera".
		<Bolivar> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Bolivar".
		<Bolivar> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Bolivar".
		<Gioia> a <Person>,<FirstName> ;
		rdfs:label "Gioia".
		<Firenze> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Firenze".
		<Firenze> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Firenze".
		<Monte_Nero> a <Location>,<GeographicalEntity>,<LandForm>,<Mountain> ;
		rdfs:label "Monte Nero".
		<Solari> a <Person>,<LastName> ;
		rdfs:label "Solari".
		<Genova> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Genova".
		<Garibaldi> a <Location>,<GeoPoliticalEntity> ;
		rdfs:label "Garibaldi".
		<Garibaldi> a <Person>,<LastName> ;
		rdfs:label "Garibaldi".
		<Vercelli> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Vercelli".
		<Lorenteggio_Barona> a <Person>,<FullName> ;
		rdfs:label "Lorenteggio Barona".
		<Sturla> a <Person>,<FirstName> ;
		rdfs:label "Sturla".
		<Albaro> a <Person>,<FirstName> ;
		rdfs:label "Albaro".
		<Nervi> a <Person>,<LastName> ;
		rdfs:label "Nervi".
		<Carignano> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Carignano".
		<Torino> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Torino".
		<CIT_Group,_Inc.> a <Organization>,<Company>,<FinancialCompany> ;
		rdfs:label "CIT Group Inc.".
		<Vanchiglia_Sassi> a <Person>,<FullName> ;
		rdfs:label "Vanchiglia Sassi".
		<Nizza> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Nizza".
		<San_Paolo> a <Location>,<Facility>,<SportsFacility> ;
		rdfs:label "San Paolo".
		<Pozzo> a <Person>,<LastName> ;
		rdfs:label "Pozzo".
		<San_Donato_di_Lecce> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "San Donato di Lecce".
		<Chiaravalle> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Chiaravalle".
		<Milano> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Milano".
		<Milano> a <Location>,<GeoPoliticalEntity>,<Adm2> ;
		rdfs:label "Milano".
		<Sandra> a <Person> ;
		rdfs:label "Sandra".
		<Fonte> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Fonte".
		<Trent> a <Person>,<FirstName> ;
		rdfs:label "Trent".
		<Rossa> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Rossa".
		<Padova> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Padova".
		<Roma> a <Location>,<GeoPoliticalEntity>,<Adm2> ;
		rdfs:label "Roma".
		<Muggiano_Olmi> a <Person>,<FullName> ;
		rdfs:label "Muggiano Olmi".
		<Maggi> a <Person>,<LastName> ;
		rdfs:label "Maggi".
		<GRECO> a <Organization>,<InternationalOrganization> ;
		rdfs:label "GRECO".
		<Greco> a <Person>,<LastName> ;
		rdfs:label "Greco".
		<Istria> a <Location>,<GeographicalEntity>,<LandForm> ;
		rdfs:label "Istria".
		<stadio_San_Siro> a <Location>,<Facility>,<SportsFacility> ;
		rdfs:label "stadio San Siro".
		<Udine> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Udine".
		<Pavese> a <Person>,<LastName> ;
		rdfs:label "Pavese".
		<Novara> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Novara".
		<Baggio> a <Person>,<LastName> ;
		rdfs:label "Baggio".
		<31.05.2021> a <time> ;
		rdfs:label "31.05.2021".
		<30_giugno_2022> a <time> ;
		rdfs:label "30 giugno 2022".
		<2013> a <time> ;
		rdfs:label "2013".
		<ora> a <time> ;
		rdfs:label "ora".
		<LUNEDÌ_31.05.2021> a <time> ;
		rdfs:label "LUNEDÌ 31.05.2021".
		<oggi> a <time> ;
		rdfs:label "oggi".
		<2020> a <time> ;
		rdfs:label "2020".
		<200_mila_euro> a "money" ;
		rdfs:label "200 mila euro".
		<2.400_euro> a "money" ;
		rdfs:label "2.400 euro".
		<200_mila_euro> a "money" ;
		rdfs:label "200 mila euro".
		<8.600_euro> a "money" ;
		rdfs:label "8.600 euro".
		<200_mila_euro> a "money" ;
		rdfs:label "200 mila euro".
		<500_euro> a "money" ;
		rdfs:label "500 euro".
		<3.600_euro> a "money" ;
		rdfs:label "3.600 euro".
		<250_mila_euro> a "money" ;
		rdfs:label "250 mila euro".
		<3.600_euro> a "money" ;
		rdfs:label "3.600 euro".
		<11.225_euro> a "money" ;
		rdfs:label "11.225 euro".
		<250_mila_euro> a "money" ;
		rdfs:label "250 mila euro".
		<250mila_euro> a "money" ;
		rdfs:label "250mila euro".
		<250_mila_euro> a "money" ;
		rdfs:label "250 mila euro".
		<949_euro> a "money" ;
		rdfs:label "949 euro".
		<250mila_euro> a "money" ;
		rdfs:label "250mila euro".
		<600_euro> a "money" ;
		rdfs:label "600 euro".
		<40mila_euro> a "money" ;
		rdfs:label "40mila euro".
		<250_mila_euro> a "money" ;
		rdfs:label "250 mila euro".
		<250.000_euro> a "money" ;
		rdfs:label "250.000 euro".
		<40.000_euro> a "money" ;
		rdfs:label "40.000 euro".
		<14_miliardi_di_euro> a "money" ;
		rdfs:label "14 miliardi di euro".
		
<https://explore.grasselliconsults.it/static/media/recessi_economici.cedfab64.pdf> a studio:Documenti ;
	rdfs:label "recessi_economici" ;
	dc:title "recessi_economici" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "covid" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "2 I focus del Sole 24 Ore Giovedì 1 Aprile 2021 – N. 8 Il procedimento di calcolo del contributo dovuto è molto semplice: basterà applicare il coefficiente dimensionale previsto dalla norma (20-60%) alla differenza tra il fatturato medio mensile del 2019 e quello del 2020, garantendo un ammontare minimo di mille o 2mila euro, rispettivamente per le Persone_Fisiche e per i soggetti diversi dalle Persone_Fisiche, e fissando un tetto di 150mila euro. [...] Tale opzione, che è irrevocabile e dovrà riguardare l'intero importo del contributo non essendone ammesso il frazionamento, dovrà essere espressa in sede di invio di apposita istanza alle Entrate, domanda che dovrà essere presentata telematicamente, anche avvalendosi di un intermediario, a partire dal 30 marzo e fino al 28 maggio 2021, seguendo le istruzioni declinate dal provvedimento del direttore delle Entrate 77923/21.O [...] L’opzione, che è irrevocabile e dovrà riguardare l'intero importo del contributo non essendone ammesso il frazionamento, dovrà essere espressa in sede di invio di apposita istanza all’agenzia delle Entrate. [...] L’importo minimo del beneficio oscilla tra mille o 2mila euro rispettivamente per le Persone_Fisiche e per gli altri soggetti";
	studio:contenuti
		<IVA>,<Andrea_Dili>,<1_Aprile_2021>,<2019>,<2020>,<2019>,<2020>,<28_maggio_2021>,<31_DICEMBRE_2018>,<30_NOVEMBRE_2019>,<1_DICEMBRE_2019>,<23_MARZO_2021>,<2019>,<2020>,<2020>,<maggio_2020>,<autunno>,<ottobre>,<novembre>,<23_marzo_2021>,<24_marzo_2021>,<2020>,<2019>,<gennaio>,<2mila_euro>,<150mila_euro>,<10_milioni_di_euro>,<1.000_euro>,<2.000_euro>,<1.000_euro>,<2.000_euro>,<150.000_euro>,<2mila_euro>,<10_milioni_di_euro>.

	<IVA> a <OtherEntity>,<IndexName>,<EconomicIndexName> ;
		rdfs:label "IVA".
		<Andrea_Dili> a <Person>,<FullName> ;
		rdfs:label "Andrea Dili".
		<1_Aprile_2021> a <time> ;
		rdfs:label "1 Aprile 2021".
		<2019> a <time> ;
		rdfs:label "2019".
		<2020> a <time> ;
		rdfs:label "2020".
		<2019> a <time> ;
		rdfs:label "2019".
		<2020> a <time> ;
		rdfs:label "2020".
		<28_maggio_2021> a <time> ;
		rdfs:label "28 maggio 2021".
		<31_DICEMBRE_2018> a <time> ;
		rdfs:label "31 DICEMBRE 2018".
		<30_NOVEMBRE_2019> a <time> ;
		rdfs:label "30 NOVEMBRE 2019".
		<1_DICEMBRE_2019> a <time> ;
		rdfs:label "1 DICEMBRE 2019".
		<23_MARZO_2021> a <time> ;
		rdfs:label "23 MARZO 2021".
		<2019> a <time> ;
		rdfs:label "2019".
		<2020> a <time> ;
		rdfs:label "2020".
		<2020> a <time> ;
		rdfs:label "2020".
		<maggio_2020> a <time> ;
		rdfs:label "maggio 2020".
		<autunno> a <time> ;
		rdfs:label "autunno".
		<ottobre> a <time> ;
		rdfs:label "ottobre".
		<novembre> a <time> ;
		rdfs:label "novembre".
		<23_marzo_2021> a <time> ;
		rdfs:label "23 marzo 2021".
		<24_marzo_2021> a <time> ;
		rdfs:label "24 marzo 2021".
		<2020> a <time> ;
		rdfs:label "2020".
		<2019> a <time> ;
		rdfs:label "2019".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		<2mila_euro> a "money" ;
		rdfs:label "2mila euro".
		<150mila_euro> a "money" ;
		rdfs:label "150mila euro".
		<10_milioni_di_euro> a "money" ;
		rdfs:label "10 milioni di euro".
		<1.000_euro> a "money" ;
		rdfs:label "1.000 euro".
		<2.000_euro> a "money" ;
		rdfs:label "2.000 euro".
		<1.000_euro> a "money" ;
		rdfs:label "1.000 euro".
		<2.000_euro> a "money" ;
		rdfs:label "2.000 euro".
		<150.000_euro> a "money" ;
		rdfs:label "150.000 euro".
		<2mila_euro> a "money" ;
		rdfs:label "2mila euro".
		<10_milioni_di_euro> a "money" ;
		rdfs:label "10 milioni di euro".
		
<https://explore.grasselliconsults.it/static/media/riforma_anziani.7bfd0c7a.pdf> a studio:Documenti ;
	rdfs:label "riforma anziani" ;
	dc:title "riforma anziani" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "anziani" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ; ;

	dc:abstract "Martedì 1 Giugno 2021 Corriere della Sera #buonenotizie Grazie alla spinta della società civile e alla disponibilità all’ascolto da parte del governo, la versione finale del Pnrr offre l’opportunità di riformare un ambito che interessagiàquasi tremilionidi italiani. [...] Cosa potrà cambiare con questa riforma per gli anziani non autosufficienti e le loro famiglie? [...] C resce l’età media della popolazione mondiale e gli anziani stanno diventando sempre più «mobili»: in auto, coi mezzi pubblici, ma anche in bicicletta e a piedi. [...] E tra i pedoni e i ciclisti gli anziani rappresentano addirittura il 50% circa di tutti gli incidenti stradali mortali.";
	studio:contenuti
		<governo>,<Cristiano_Gori>,<Giuseppe_Conte>,<Italia>,<Trento>,<Trento>,<Trento>,<Enrico_Brizioli>,<Gregorio_Mancino>,<Milano>,<Milano>,<www.luoghicura.it>,<Analysis_Group>,<Ospedale_Mica_Male>,<www.fondazionedemarchi.it>,<Anna_Banchero>,<Giulio>,<Antonio_Guaita>,<Ty>,<Instituto_de_la_Mujer>,<#buonenotizie>,<Draghi>,<Terzo>,<Corriere_della_Sera>,<Unione_Europea>,<Martedì_1_Giugno_2021>,<Ora>,<GIULIO>,<gennaio>,<ora>,<essere_introdotta_entro_la_primavera_del_2023>,<oggi>,<2010>,<2009>,<essere_introdotte_entro_la_primavera_del_2023>,<oggi>.

	<governo> a <Organization>,<Government> ;
		rdfs:label "governo".
		<Cristiano_Gori> a <Person>,<FullName> ;
		rdfs:label "Cristiano Gori".
		<Giuseppe_Conte> a <Person>,<FullName> ;
		rdfs:label "Giuseppe Conte".
		<Italia> a <Location>,<GeoPoliticalEntity>,<Country> ;
		rdfs:label "Italia".
		<Trento> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Trento".
		<Trento> a <Person>,<LastName> ;
		rdfs:label "Trento".
		<Trento> a <Location>,<GeoPoliticalEntity>,<Adm2> ;
		rdfs:label "Trento".
		<Enrico_Brizioli> a <Person>,<FullName> ;
		rdfs:label "Enrico Brizioli".
		<Gregorio_Mancino> a <Person>,<FullName> ;
		rdfs:label "Gregorio Mancino".
		<Milano> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Milano".
		<Milano> a <Location>,<GeoPoliticalEntity>,<Adm2> ;
		rdfs:label "Milano".
		<www.luoghicura.it> a <Id>,<Url> ;
		rdfs:label "www.luoghicura.it".
		<Analysis_Group> a <Organization> ;
		rdfs:label "Analysis Group".
		<Ospedale_Mica_Male> a <Organization>,<Company>,<HealthcareCompany>,<HealthcareServicesCompany>,<Hospitals> ;
		rdfs:label "Ospedale Mica Male".
		<www.fondazionedemarchi.it> a <Id>,<Url> ;
		rdfs:label "www.fondazionedemarchi.it".
		<Anna_Banchero> a <Person>,<FullName> ;
		rdfs:label "Anna Banchero".
		<Giulio> a <Person>,<FirstName> ;
		rdfs:label "Giulio".
		<Antonio_Guaita> a <Person>,<FullName> ;
		rdfs:label "Antonio Guaita".
		<Ty> a <Person>,<FirstName> ;
		rdfs:label "Ty".
		<Instituto_de_la_Mujer> a <Organization>,<PublicInstitution> ;
		rdfs:label "Instituto de la Mujer".
		<#buonenotizie> a <Id>,<Hashtag> ;
		rdfs:label "#buonenotizie".
		<Draghi> a <Person>,<LastName> ;
		rdfs:label "Draghi".
		<Terzo> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Terzo".
		<Corriere_della_Sera> a <Product>,<CulturalProduct>,<Printing>,<Newspaper> ;
		rdfs:label "Corriere della Sera".
		<Unione_Europea> a <Location>,<GeoPoliticalEntity> ;
		rdfs:label "Unione Europea".
		<Martedì_1_Giugno_2021> a <time> ;
		rdfs:label "Martedì 1 Giugno 2021".
		<Ora> a <time> ;
		rdfs:label "Ora".
		<GIULIO> a <time> ;
		rdfs:label "GIULIO".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		<ora> a <time> ;
		rdfs:label "ora".
		<essere_introdotta_entro_la_primavera_del_2023> a <time> ;
		rdfs:label "essere introdotta entro la primavera del 2023".
		<oggi> a <time> ;
		rdfs:label "oggi".
		<2010> a <time> ;
		rdfs:label "2010".
		<2009> a <time> ;
		rdfs:label "2009".
		<essere_introdotta_entro_la_primavera_del_2023> a <time> ;
		rdfs:label "essere introdotte entro la primavera del 2023".
		<oggi> a <time> ;
		rdfs:label "oggi".
		
<https://explore.grasselliconsults.it/static/media/riscossione.38536a2f.pdf> a studio:Documenti ;
	rdfs:label "riscossione" ;
	dc:title "riscossione" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "covid" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "6 I focus del Sole 24 Ore Giovedì 1 Aprile 2021 – N. 8 L’ articolo 4 del decreto Sostegni (Dl 41/2021) proroga al 30 aprile il periodo di sospensione dei pagamenti all’agente della riscossione, previsto nell’articolo 68 del Cura Italia (decreto legge 18/2020). [...] La proroga dei pagamenti Per effetto dell’articolo 4, tutti i versamenti con scadenza tra l’8 marzo 2020 (entrata in vigore del decreto legge 18/2020) e il 30 aprile 2021 sono sospesi. [...] La proroga dei termini Il decreto Sostegni contiene inoltre una proroga di 24 mesi dei termini di decadenza e prescrizione per la notifica delle cartelle che agisce in due direzioni. [...] Per le partite che danno luogo alla proroga dei termini di decadenza e prescrizione, la suddetta scadenza diventa pertanto di 21 mesi dall’affidamento del carico.";
	studio:contenuti
		<Luigi_Lovecchio>,<Repubblica>,<1_Aprile_2021>,<8_marzo_2020>,<30_aprile_2021>,<maggio>,<8_marzo_2020>,<2018>,<8_marzo_2020>,<31_dicembre_2021>,<8_marzo_2020>,<31_dicembre_2021>,<2018>,<2017>,<ora>,<2020>,<31_dicembre_2022>,<8_marzo_2020>,<2018>,<2023>,<5mila_euro>,<100.000_euro>,<5.000_euro>,<5mila_euro>.

	<Luigi_Lovecchio> a <Person>,<FullName> ;
		rdfs:label "Luigi Lovecchio".
		<Repubblica> a <Organization>,<Company>,<ConsumerGoodsCompany>,<Discretionary>,<ConsumerServicesCompany>,<MediaCompany> ;
		rdfs:label "Repubblica".
		<1_Aprile_2021> a <time> ;
		rdfs:label "1 Aprile 2021".
		<8_marzo_2020> a <time> ;
		rdfs:label "8 marzo 2020".
		<30_aprile_2021> a <time> ;
		rdfs:label "30 aprile 2021".
		<maggio> a <time> ;
		rdfs:label "maggio".
		<8_marzo_2020> a <time> ;
		rdfs:label "8 marzo 2020".
		<2018> a <time> ;
		rdfs:label "2018".
		<8_marzo_2020> a <time> ;
		rdfs:label "8 marzo 2020".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<8_marzo_2020> a <time> ;
		rdfs:label "8 marzo 2020".
		<31_dicembre_2021> a <time> ;
		rdfs:label "31 dicembre 2021".
		<2018> a <time> ;
		rdfs:label "2018".
		<2017> a <time> ;
		rdfs:label "2017".
		<ora> a <time> ;
		rdfs:label "ora".
		<2020> a <time> ;
		rdfs:label "2020".
		<31_dicembre_2022> a <time> ;
		rdfs:label "31 dicembre 2022".
		<8_marzo_2020> a <time> ;
		rdfs:label "8 marzo 2020".
		<2018> a <time> ;
		rdfs:label "2018".
		<2023> a <time> ;
		rdfs:label "2023".
		<5mila_euro> a "money" ;
		rdfs:label "5mila euro".
		<100.000_euro> a "money" ;
		rdfs:label "100.000 euro".
		<5.000_euro> a "money" ;
		rdfs:label "5.000 euro".
		<5mila_euro> a "money" ;
		rdfs:label "5mila euro".
		
<https://explore.grasselliconsults.it/static/media/sanatoria.62df4879.pdf> a studio:Documenti ;
	rdfs:label "sanatoria" ;
	dc:title "sanatoria" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "covid" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ;

	dc:description "I focus del Sole 24 Ore Giovedì 1 Aprile 2021 – N. 8 5 Deve infatti trattarsi di carichi affidati tra il 1° gennaio 2000 e il 31 dicembre 2010. [...] In particolare, le prime potranno essere versate entro il prossimo 31 luglio, per le seconde il nuovo termine è il 30 novembre 2021. [...] La decadenza Opera automaticamente qualora il pagamento anche di una sola rata venga eseguito oltre la soglia prevista dall’articolo 4 del decreto 30mila Il requisito Il debitore deve avere un reddito imponibile dichiarato per l’anno 2019, non superiore a 30mila euro l’aggio di riscossione e delle spese di notifica e riscossione (ad esempio, spese per iscrizione del fermo amministrativo). [...] Essa prevede che il reddito imponibile (al netto dunque degli oneri deducibili) per l’anno 2019 non superi 30mila euro.";
	studio:contenuti
		<Unione_Europea>,<Luigi_Lovecchio>,<Opera>,<Imposta_sul_Reddito_delle_Persone_Fisiche>,<IMU>,<corte_dei_conti>,<1_Aprile_2021>,<gennaio>,<31_dicembre_2010>,<marzo>,<30_novembre_2021>,<2021>,<2019>,<novembre>,<23/11>,<2020>,<2000>,<2020>,<2020>,<5mila_euro>,<30mila_euro>,<mille_euro>,<5mila_euro>,<5mila_euro>,<5mila_euro>,<30mila_euro>,<4.500_euro>,<3mila_euro>,<1.500_euro>,<30mila_euro>.

	<Unione_Europea> a <Location>,<GeoPoliticalEntity> ;
		rdfs:label "Unione Europea".
		<Luigi_Lovecchio> a <Person>,<FullName> ;
		rdfs:label "Luigi Lovecchio".
		<Opera> a <Location>,<GeoPoliticalEntity>,<City> ;
		rdfs:label "Opera".
		<Imposta_sul_Reddito_delle_Persone_Fisiche> a <OtherEntity>,<IndexName>,<EconomicIndexName> ;
		rdfs:label "Imposta sul Reddito delle Persone Fisiche".
		<IMU> a <OtherEntity>,<Rule>,<LawRule> ;
		rdfs:label "IMU".
		<corte_dei_conti> a <Organization>,<PublicInstitution> ;
		rdfs:label "corte dei conti".
		<1_Aprile_2021> a <time> ;
		rdfs:label "1 Aprile 2021".
		<gennaio> a <time> ;
		rdfs:label "gennaio".
		<31_dicembre_2010> a <time> ;
		rdfs:label "31 dicembre 2010".
		<marzo> a <time> ;
		rdfs:label "marzo".
		<30_novembre_2021> a <time> ;
		rdfs:label "30 novembre 2021".
		<2021> a <time> ;
		rdfs:label "2021".
		<2019> a <time> ;
		rdfs:label "2019".
		<novembre> a <time> ;
		rdfs:label "novembre".
		<23/11> a <time> ;
		rdfs:label "23/11".
		<2020> a <time> ;
		rdfs:label "2020".
		<2000> a <time> ;
		rdfs:label "2000".
		<2020> a <time> ;
		rdfs:label "2020".
		<2020> a <time> ;
		rdfs:label "2020".
		<5mila_euro> a "money" ;
		rdfs:label "5mila euro".
		<30mila_euro> a "money" ;
		rdfs:label "30mila euro".
		<mille_euro> a "money" ;
		rdfs:label "mille euro".
		<5mila_euro> a "money" ;
		rdfs:label "5mila euro".
		<5mila_euro> a "money" ;
		rdfs:label "5mila euro".
		<5mila_euro> a "money" ;
		rdfs:label "5mila euro".
		<30mila_euro> a "money" ;
		rdfs:label "30mila euro".
		<4.500_euro> a "money" ;
		rdfs:label "4.500 euro".
		<3mila_euro> a "money" ;
		rdfs:label "3mila euro".
		<1.500_euro> a "money" ;
		rdfs:label "1.500 euro".
		<30mila_euro> a "money" ;
		rdfs:label "30mila euro".
		
<https://explore.grasselliconsults.it/static/media/successione.c6742aaa.pdf> a studio:Documenti ;
	rdfs:label "successione" ;
	dc:title "successione" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "successione" ;

	dc:autors "articolo" ;

	foaf:depiction documenti:doc.jpg ; ;

	dc:abstract "Vale la pena di ricordare che nel 2020 — ripetiamo, nel 2020, nel pieno della pandemia e dell’esplosione di ristori a vantaggio di questa o quella categoria— la pressione fiscale è cresciuta in Italia di 7 decimi di punto. C’è qualcuno disposto a sostenere che, in nome della lotta alle diseguaglianze, sarebbe do- vuta aumentare di più? [...] La fantasia della politica non conosce limiti ma è difficile immaginare che il bonus 18enni possa essere annoverato fra le spese di investimento. [...] In unmondo dove i capitali sono ben più mobili di quanto non lo fossero all’epoca, la preoccupazione di Einaudi è sorprendentemente attuale: le imposte di successione tendono a colpire soprattutto il risparmio che non si sposta e che dovrebbe servire al Paese per alimentare gli investimenti. [...] Quel che rileva, in altre parole, è il ruolo attribuito all’imposta di successione all’interno del sistema fiscale nel suo complesso.";
	studio:contenuti
		<Luigi_Einaudi>,<Giuliano_Amato>,<Italia>,<Silvio_Berlusconi>,<governo>,<Eugenio>,<Eugenio>,<Bruno_Leoni>,<Fiorini>,<Vincenzo_Visco>,<Ottaviano_Del_Turco>,<Enrico_Letta>,<Irlanda>,<Svezia>,<Alberto_Mingardi>,<Nicola_Rossi>,<Romano_Prodi>,<31.05.2021>,<2020>,<2020>,<1946>,<oggi>,<2000>,<oggi>,<2000>,<1946>,<2020>,<350.000_euro>,<un_milione_di_euro>.

	<Luigi_Einaudi> a <Person>,<FullName> ;
		rdfs:label "Luigi Einaudi".
		<Giuliano_Amato> a <Person>,<FullName> ;
		rdfs:label "Giuliano Amato".
		<Italia> a <Location>,<GeoPoliticalEntity>,<Country> ;
		rdfs:label "Italia".
		<Silvio_Berlusconi> a <Person>,<FullName> ;
		rdfs:label "Silvio Berlusconi".
		<governo> a <Organization>,<Government> ;
		rdfs:label "governo".
		<Eugenio> a <Person>,<LastName> ;
		rdfs:label "Eugenio".
		<Eugenio> a <Person>,<FirstName> ;
		rdfs:label "Eugenio".
		<Bruno_Leoni> a <Person>,<FullName> ;
		rdfs:label "Bruno Leoni".
		<Fiorini> a <Person>,<LastName> ;
		rdfs:label "Fiorini".
		<Vincenzo_Visco> a <Person>,<FullName> ;
		rdfs:label "Vincenzo Visco".
		<Ottaviano_Del_Turco> a <Person>,<FullName> ;
		rdfs:label "Ottaviano Del Turco".
		<Enrico_Letta> a <Person>,<FullName> ;
		rdfs:label "Enrico Letta".
		<Irlanda> a <Location>,<GeoPoliticalEntity>,<Country> ;
		rdfs:label "Irlanda".
		<Svezia> a <Location>,<GeoPoliticalEntity>,<Country> ;
		rdfs:label "Svezia".
		<Alberto_Mingardi> a <Person>,<FullName> ;
		rdfs:label "Alberto Mingardi".
		<Nicola_Rossi> a <Person>,<FullName> ;
		rdfs:label "Nicola Rossi".
		<Romano_Prodi> a <Person>,<FullName> ;
		rdfs:label "Romano Prodi".
		<31.05.2021> a <time> ;
		rdfs:label "31.05.2021".
		<2020> a <time> ;
		rdfs:label "2020".
		<2020> a <time> ;
		rdfs:label "2020".
		<1946> a <time> ;
		rdfs:label "1946".
		<oggi> a <time> ;
		rdfs:label "oggi".
		<2000> a <time> ;
		rdfs:label "2000".
		<oggi> a <time> ;
		rdfs:label "oggi".
		<2000> a <time> ;
		rdfs:label "2000".
		<1946> a <time> ;
		rdfs:label "1946".
		<2020> a <time> ;
		rdfs:label "2020".
		<350.000_euro> a "money" ;
		rdfs:label "350.000 euro".
		<un_milione_di_euro> a "money" ;
		rdfs:label "un milione di euro".
		
<https://www.ettimpiantisrl.com/wp-content/uploads/2020/06/SINTESI-SUPERBONUS-110.pdf> a studio:Documenti ;
	rdfs:label "SUPERBONUS-110" ;
	dc:title "SUPERBONUS-110" ;
	dc:language "it" ;
	dc:date 2021;
	dc:about "superbonus" ;

	dc:autors "null" ;

	foaf:depiction documenti:doc.jpg ; ;

	dc:abstract "34/2020 (Decreto Rilancio) che dovrà essere convertito in legge entro 60 gg. dalla sua pubblicazione e che quindi potrebbe essere soggetto ad emendamenti, per essere operativo occorrerà attendere sia i decreti attuativi che le indicazioni operative dell’agenzia delle entrate. [...] GLI INTERVENTI CHE POTRANNO ACCEDERE AL NUOVO SUPERBONUS DEL 110% interventi di isolamento termico delle superfici opache verticali e orizzontali che interessano l’involucro dell’edificio con incidenza superiore al 25% (con riduzione di due classi di consumo energetico). [...] Interventi, sulle parti comuni degli edifici, per la sostituzione degli impianti di climatizzazione con impianti centralizzati per il riscaldamento, il raffrescamento o la fornitura di acqua calda sanitaria a pompa di calore ivi inclusi gli impianti ibridi, geotermici anche abbinati all’installazione di impianti fotovoltaici e relativi sistemi di accumulo, ovvero con impianti microgenerazione. [...] L’attuale versione del decreto rilancio prevede il SUPERBONUS del 110% anche per gli interventi di miglioramento sismico (SismaBonus).";
	studio:contenuti
		<max>,<Cam>,<Enea>,<European_Nuclear_Energy_Agency>,<31_Dicembre_2021>,<11_Ottobre_2017>.

	<max> a <Person> ;
		rdfs:label "max".
		<Cam> a <Person>,<FullName> ;
		rdfs:label "Cam".
		<Enea> a <Person>,<FirstName> ;
		rdfs:label "Enea".
		<European_Nuclear_Energy_Agency> a <Organization>,<InternationalOrganization> ;
		rdfs:label "European Nuclear Energy Agency".
		<31_Dicembre_2021> a <time> ;
		rdfs:label "31 Dicembre 2021".
		<11_Ottobre_2017> a <time> ;
		rdfs:label "11 Ottobre 2017".
}
`