import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

//Components
import ProtectedRoute from "./components/ProtectedRoute";
import Home from "./components/Home";
import Login from "./components/Login";

//PDF pages
import Pdf0 from "./components/Dati/PDF/aiuti_settore_fieristico.pdf"
import Pdf1 from "./components/Dati/PDF/ammortizzatori_sociali.pdf"
import Pdf2 from "./components/Dati/PDF/avvisi_bonari.pdf"
import Pdf3 from "./components/Dati/PDF/decreto_sostegni_adempimenti.pdf"
import Pdf4 from "./components/Dati/PDF/decreto_sostegni_aiuti_e_mercato.pdf"
import Pdf5 from "./components/Dati/PDF/decreto_sostegni_crisi_impresa.pdf"
import Pdf6 from "./components/Dati/PDF/decreto_sostegni_debiti.pdf"
import Pdf7 from "./components/Dati/PDF/decreto_sostegni_fondo_perduto.pdf"
import Pdf8 from "./components/Dati/PDF/decreto_sostegni_immobili.pdf"
import Pdf9 from "./components/Dati/PDF/decreto_sostegni_imprese_e_consumatori.pdf"
import Pdf10 from "./components/Dati/PDF/decreto_sostegni_integrazione_al_reddito.pdf"
import Pdf11 from "./components/Dati/PDF/decreto_sostegni_lavoratori_autonomi.pdf"
import Pdf12 from "./components/Dati/PDF/decreto_sostegni_liquidità.pdf"
import Pdf13 from "./components/Dati/PDF/decreto_sostegni_rivalutazioni.pdf"
import Pdf14 from "./components/Dati/PDF/decreto_sostegni_tempo_determinato.pdf"
import Pdf15 from "./components/Dati/PDF/decreto_sostegni_welfare.pdf"
import Pdf16 from "./components/Dati/PDF/flessibilità_e_garanzie.pdf"
import Pdf17 from "./components/Dati/PDF/fondo_perduto_aiuti_plafond_10_milioni.pdf"
import Pdf18 from "./components/Dati/PDF/grandi_crisi.pdf"
import Pdf19 from "./components/Dati/PDF/il_fondo_perduto_ricavi_fino_a_100_mila.pdf"
import Pdf20 from "./components/Dati/PDF/il_fondo_perduto_ricavi_inferioni_a_10_milioni.pdf"
import Pdf21 from "./components/Dati/PDF/Incentivi_tecnologia_Fisco.pdf"
import Pdf22 from "./components/Dati/PDF/mutui_giovani.pdf"
import Pdf23 from "./components/Dati/PDF/recessi_economici.pdf"
import Pdf24 from "./components/Dati/PDF/riforma_anziani.pdf"
import Pdf25 from "./components/Dati/PDF/riscossione.pdf"
import Pdf26 from "./components/Dati/PDF/sanatoria.pdf"
import Pdf27 from "./components/Dati/PDF/setup.ipynb"
import Pdf28 from "./components/Dati/PDF/successione.pdf"
import Pdf29 from "./components/Dati/PDF/SUPERBONUS-110.pdf"

import './styles/app.css';

function App(props) {

  const { 
    isAuthenticated, 
    isVerifying,
    watermarkSvg,
    criteria,
    target } = props;
  
    // console.log(props)
  return (
    <div>
      <Switch>
        <ProtectedRoute
          exact
          path="/"
          component={Home}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          watermarkSvg={watermarkSvg}
          criteria={criteria}
		      target={target}
        />
        <Route path="/login" component={Login} />
      </Switch>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    watermarkSvg: state.graph.watermarkSvg,
		watermarkUrl: state.graph.watermarkUrl,
		criteria: state.graph.criteria,
		target: state.graph.target,
  };
}

function mapDispatchToProps(dispatch) {
	return {
		onSearchCriteriaChanged: (value) => dispatch({
			type: 'UPDATECRITERIA',
			criteria: value
		}),
	};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
})(App)

