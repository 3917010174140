import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";

// import { createStore } from 'redux';
// import reducer from './store/reducer';
// import {Provider} from 'react-redux';
// import App from './App';

ReactDOM.render(<Root />, document.getElementById("root"));

// const store = createStore(reducer);
// ReactDOM.render(
//   <React.Fragment>
//     <Provider store= {store}>
//       <App/>
//     </Provider>
//   </React.Fragment>,
//   document.getElementById('root')
// );
