import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import request from 'superagent'
import "../styles/document_button.css";

function Document_button () {

  const [results, setResults] = React.useState(false); 
  const [resultsOpen, setResultsOpen] = React.useState(false); 
  const [orderAlph, setOrderAlph] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState(false); 

  const [categories, setCategories] = React.useState([]); 
  const [uniqueCategories, setUniqueCategories] = React.useState([]); 
  const [catSelected, setCatSelected] = React.useState(''); 

  useEffect(() => {
    try {
      setCategories(results.map(e => e.about.value)); 
      // console.log(categories)
      setUniqueCategories([...new Set(categories)])
      // console.log(uniqueCategories)
    }
    catch {
    }
  }, [filterOpen]);

  async function clickFunc() {  

    setResultsOpen(!resultsOpen)

    request
      .get('https://bypasscorsproxy.herokuapp.com/https://blaze.grasselliconsults.it/blazegraph/namespace/kb/sparql')
      .accept('application/json')

      .query({ query: 
        `     
        PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
        PREFIX owl:  <http://www.w3.org/2002/07/owl#>
        PREFIX bds: <http://www.bigdata.com/rdf/search#>
        PREFIX skos: <http://www.w3.org/2004/02/skos/core#>

        SELECT ?inst ?class ?label ?blankType ?about
        WHERE {
            {
                SELECT DISTINCT ?inst WHERE {
                    
                    { <http://grasselliconsults.it/Documenti> ?link ?inst . FILTER(isIri(?inst)) } UNION { ?inst ?link <http://grasselliconsults.it/Documenti> . FILTER(isIri(?inst)) }
                }
                LIMIT 100 OFFSET 0
            }
            OPTIONAL {?inst rdf:type ?foundClass}
                BIND (coalesce(?foundClass, owl:Thing) as ?class)
                OPTIONAL {?inst rdfs:label ?label}
                OPTIONAL {?inst dc:about ?about}
        } 
        `, 
      format: 'json'})

      .buffer(true)

      .end((err, resp) => {
        if (!err) {
          var result = JSON.parse(resp.text).results.bindings
          setResults(result)
          // console.log(result)
        }
        }
      )
  }

  function filterFun() {
    if (filterOpen===true) {
      setCatSelected('')
    }
    setFilterOpen(!filterOpen);
  }

  return (
    <div>
      <div className="header">
        <div className="documentButton">
          <Button onClick={clickFunc}>Documenti</Button>
        </div>
      </div>
      <div className={resultsOpen? 'boxDocu': ''}>
        {
          results && resultsOpen ? 
            <div>
              <Button className={orderAlph ? 'closeFilter' : 'closeFilter'} onClick={() => setOrderAlph(!orderAlph)}>
              {orderAlph ? 'A/Z' : 'Z/A'}
              </Button> 
              <Button className={filterOpen ? 'openFilter' : 'closeFilter'} onClick={filterFun}>Filtra</Button>
            </div>
          : null
        }
        {
          results && resultsOpen && filterOpen && uniqueCategories!=''?
            <div className='filterBox'>
            {uniqueCategories
                .map(e=>{
                  if(e==catSelected) {
                    return (
                      <div key={e}>
                        <button onClick={() => setCatSelected(e)} className='filterSel'>{e}</button>
                      </div>
                    )
                  }
                  if(e!==catSelected) {
                    // console.log(e,catSelected)
                    return (
                      <div key={e}>
                        <button onClick={() => setCatSelected(e)} className='filterNoSel'>{e}</button>
                      </div>
                    )
                  }
              })}
            </div>
          : ''
        }
        {
          results && resultsOpen && orderAlph ? 
            results.filter(e => e.about.value.includes(catSelected))
              .sort((a, b) => a.label.value > b.label.value ? 1 : -1)
              .map(e=>{
                return (
                  <div className='valueDoc' key={e}>
                      <a href={e.inst.value}>
                        {
                        e.label.value
                        .replace('decreto_sostegni_','')
                        .replace('il fondo perduto_','')
                        .replace('il_fondo_perduto_','')
                        .replace('fondo perduto_','')
                        .replace('fondo_perduto_','')
                        .replace('_',' ')
                        .replace('_',' ')
                        .replace('_',' ')
                        .replace('_',' ')
                        .replace('_',' ')
                        }
                      </a>
                  </div>
                )
            })
          : ''
        }
        {
          results && resultsOpen && !orderAlph ? 
            results.filter(e => e.about.value.includes(catSelected))
              .sort((a, b) => a.label.value < b.label.value ? 1 : -1)
              .map(e=>{
                return (
                  <div className='valueDoc' key={e}>
                      <a href={e.inst.value}>
                        {
                        e.label.value
                        .replace('decreto_sostegni_','')
                        .replace('il fondo perduto_','')
                        .replace('il_fondo_perduto_','')
                        .replace('fondo perduto_','')
                        .replace('fondo_perduto_','')
                        .replace('_',' ')
                        .replace('_',' ')
                        .replace('_',' ')
                        .replace('_',' ')
                        .replace('_',' ')
                        }
                      </a>
                  </div>
                )
            })
          : ''
        }
      </div>
    </div>
  );
}
  
export default Document_button;


