import React, { Component } from "react";
import { logoutUser } from "../actions";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from '@material-ui/core/styles';

import "../styles/logout_button.css";

class Logout_button extends Component {
  handleLogout = () => {
    const { dispatch } = this.props;
    dispatch(logoutUser());
  };

  render() {
    const { isLoggingOut, logoutError } = this.props;


    return (
      <div className="header">
        <div className="logout">
            <Button
              onClick={this.handleLogout}
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              disableElevation="true"
            >
              Esci
            </Button>
          {isLoggingOut && <p>Logging Out....</p>}
          {logoutError && <p>Error logging out</p>}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError
  };
}

export default connect(mapStateToProps)(Logout_button);
